import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link} from 'react-router-dom'
import { getHomeContent } from '../actions/home'
import { Container, Carousel, Row, Col, Modal } from 'react-bootstrap'
import ShopInfo from '../components/shop-info'
import ProductCard from '../components/product-card'
import { Carousel as TDCarousel } from '3d-react-carousal'
import { Carousel as ProductCarousel } from '../components/carousel'
import CarouselGrey from '../components/carousel-grey'
import CarouselOffer from '../components/carousel-offer'
import MainCategories from '../components/main-categories'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import ReactPixel from 'react-facebook-pixel'
import { PUBLIC_URL, REACT_APP_API_PUBLIC_FOLDER } from '../helpers/domain'
import NewsletterModal from '../components/newsletter-modal'

const Home = () => {
  const dispatch = useDispatch()
  const [sliders,setSliders] = useState([])
  const [latestProducts, setLatestProducts] = useState([])
  const [featuredCategories, setFeaturedCategories] = useState([])
  const [bestSeller, setBestSeller] = useState([])
  const [suggesSlides, setSuggestSlides] = useState([])
  const [offers, setOffers] = useState([])
  const [mainCategories, setMainCategories] = useState([])
  const [featuredBrands, setFeaturedBrands] = useState([])
  const [freeShippingAmount, setFreeShippingAmount] = useState("")
  const { isFetching } = useSelector(state => state.getHomeContent)
  let apiPublicFolder = REACT_APP_API_PUBLIC_FOLDER + 'storage/'
  let publicUrl = PUBLIC_URL

  // const [modalShow, setModalShow] = useState(true);
  // const handleClose = () => setModalShow(false);
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 475px)").matches)

  useEffect(()=> {
    window.matchMedia("(min-width: 475px)")
      .addEventListener('change', e => setMatches( e.matches ));
    dispatch(getHomeContent())
      .then(response => {
        let { sliders: storedSliders, latest_products, featured_categories, best_seller_products, recommended_products, products_in_offer, main_categories, featured_brands, free_shipping} = response.value
        setSliders(storedSliders)
        setLatestProducts(latest_products)
        setFeaturedCategories(featured_categories)
        setBestSeller(best_seller_products)
        setSuggestSlides(getSuggestSlides(recommended_products))
        setOffers(products_in_offer)
        setMainCategories(main_categories)
        setFeaturedBrands(featured_brands)
        setFreeShippingAmount(free_shipping)
        console.log(free_shipping)
      })
    ReactPixel.init('261944151355410')
    ReactPixel.pageView()
  },[])

  const getSuggestSlides = recommended => {
    console.log("apiPublicFolder", apiPublicFolder, publicUrl, PUBLIC_URL, REACT_APP_API_PUBLIC_FOLDER)
    let suggest = []
    recommended.forEach(e => {
      let img = e?.images.length ? 
        <img className="product-image img-fluid" loading="lazy" style={{maxHeight: '480px'}} src={`${apiPublicFolder}${e.images[0].path}`} alt="Imagen de producto" />
        :        
        <img className="product-image img-fluid" loading="lazy" src={`${publicUrl}/images/nofoto.png`} alt="Imagen de producto" />
      suggest.push(<Link to={`/producto/${e.slug}`}>{img}</Link>)
    })
    return suggest
  }

  return (
    false ?
      <div style={{width: '100%',height: '100vh', position: 'fixed', 'top': '0', left: '0', background: '#fff','zIndex': '999999999'}}>
        <img 
        className="product-image img-fluid" 
        style={{'position': 'absolute', 'top': '50%','left':'50%','transform':'translate(-50%,-50%)'}}
        src={`${publicUrl}/images/preloader2.gif`} 
        alt="Imagen de producto" />
      </div>
    :
      <>
        <Container fluid className="main-carousel">
          {/* <Modal show={modalShow} onHide={handleClose} id="modalnewsletter">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{backgroundImage:  matches? "url('images/newsletter_bkg.png')" : "url('images/newsletter_bkg_mobile.png')" }}>
              <a href='https://optin.myperfit.com/subscribe/entelequia/Ki1VK9NH' className="btn" target='_blank' rel="noreferrer">
                Registrate acá
              </a>
            </Modal.Body>
          </Modal> */}
          <Row>
            <Carousel style={{width : "100%"}}>
              {
                sliders.length > 0 &&
                  sliders.map((s,i) => 
                    <Carousel.Item key={i}>
                      { s.link == null?    
                        s.path_mobile ?
                          <>
                            <img src={apiPublicFolder+s.path_mobile} alt="Slider" className="img-fluid mobile"/>
                            <img src={apiPublicFolder+s.path} alt="Slider" className="img-fluid desktop" style={{width : "100%"}}/> 
                          </>
                          :
                          <img src={apiPublicFolder+s.path} alt="Slider" className="img-fluid"/>
                      :
                          <a href={s.link}>
                            {s.path_mobile? 
                              <>
                                <img src={apiPublicFolder+s.path_mobile} alt="Slider" className="img-fluid mobile"/>
                                <img src={apiPublicFolder+s.path} alt="Slider" className="img-fluid desktop" style={{width : "100%"}}/>
                              </>
                            :
                              <img src={apiPublicFolder+s.path} alt="Slider" className="img-fluid"/>
                            }
                          </a>
                      }
                    </Carousel.Item>
                  )
              }
            </Carousel>
          </Row>
        </Container>
        <Container fluid className="max-container pt-2">
          {/*<a href='/oferta-ninez'>
            <picture className='img-fluid'>
              <source srcSet="/images/oferta_ninez.png"
                media="(min-width: 766px)"/>
              <img src="/images/oferta_ninez_mobile.png" className='img-fluid mt-5' alt="Dia de la niñez!" />
            </picture>
          </a>*/}
          <ShopInfo freeShippingAmount={freeShippingAmount} />
          <section className="news">
            <Col xs={12}>
              <Link to="/novedades"><h3 className="title-section">Ultimas novedades <img src="images/bars-red.jpg" className="bars d-none d-md-inline" alt="imagen"/></h3></Link>
            </Col>
            <ProductCarousel products={latestProducts}/>
          </section>
          <Row>
            <Col md={4} className="mb-5">
              <div className="slider-gris best-seller">
                <div className="header red">Mas <strong>vendidos</strong><img src="images/icons/bars-white.png" className="bars" alt="imagen"/></div>
                <Carousel >
                  { bestSeller?.map((e,i) => 
                    <Carousel.Item key={i}>
                      <ProductCard product={e}/>
                    </Carousel.Item>
                  )}
                </Carousel>
              </div>
              <Link to="/productos/juegos">
                <img src="images/juegos.png" alt="Juegos" className="img-fluid" style={{width: '100%'}} />
              </Link>
            </Col>
            <Col md={8}>
              {featuredCategories?.map((e,i) => 
                <div className="slider-gris category-slide" key={i}>
                  <Link to={`/productos/${e.slug}`}><h4 className="title">{e.name}</h4></Link>
                  <CarouselGrey mini={i === 0 ? true : false} products={e.products}/>
                </div>
              )}
            </Col>
          </Row>
          { offers?.length > 0 &&
            <Row className="offers">
              <Col xs={12}>
                <Link to="/ofertas"><h3 className="title-section">Ofertas <img src="images/bars-green.jpg" className="bars" alt="imagen"/></h3></Link> 
              </Col>
              <Col xs={12}>
                <CarouselOffer products={offers} key={offers.length}/>
              </Col>
              <Col xs={12} className="text-center mt-3">
                <Link className="see-more" to={`/ofertas/`}>Ver más</Link>
              </Col>
            </Row>
          }
          <div className="main-categories">
            <MainCategories categories={mainCategories}/>
          </div>
        </Container>
        <Container fluid style={{backgroundImage: 'url("images/bkg_recomendados.jpg")' }} className="pl-0 pr-0">
          <Row className="suggest max-container">
            <Col lg={4}>
              <p>No te pierdas los</p>
              <h3><span>RECO</span><span>MEN</span><span>DADOS</span></h3>
              <h4>DE ENTELEQUIA <img src="images/icons/bars-white.png" className="bars" alt="imagen"/></h4>
            </Col>
            <Col lg={8}>
              <TDCarousel slides={suggesSlides}/>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="brands">
          { featuredBrands.length > 0 &&
              <OwlCarousel className='owl-theme' margin={10} loop responsive={{0: {items: 2},760: {items: 3},992: {items: 5}}}>
                {featuredBrands?.map((e,i) => 
                  <div key={i}>
                    <span className="helper"></span>
                    <Link to={`/productos/?editorial=${e.slug}`}><img src={apiPublicFolder+e.path} alt="Marcas" className="img-fluid"/></Link>
                  </div>
                )}
              </OwlCarousel>
          }
          </Row>
        </Container>
      </>
  )
}

export default Home