import React, {useEffect, useState} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import history from '../history'

const CheckoutMakePayment = props => { 
  const [order, setOrder ] = useState(null)

  useEffect(() => {
    let {order: _order} = props.location.state
    if(_order == null){
      history.push('/cuenta/mis-pedidos')
    } else{
      setOrder(_order)
    }
  }, [])
  
  return (
    <Container fluid className="inner-page checkout-payment max-container">
      <div className="breadcrumb pl-0">
        Inicio / CARRITO DE COMPRAS / <span> CHECKOUT</span>
      </div>
      <h3 className="mb-4">FINALIZAR COMPRA</h3>
      <Row>
        <Col xs={12} className="green-box">
          <h4>DETALLES DEL PEDIDO</h4>
          <table>
            <tr>
              <td><strong>Producto</strong></td>
              <td><strong>Total</strong></td>
            </tr>
            { order?.orderItems.map((orderItem, i) => 
              <tr key={i}>
                <td>{orderItem.productTitle} x{orderItem.quantity}</td>
                <td>{orderItem.totalPrice.currency}{orderItem.totalPrice.amount}</td>
              </tr>
            )}
            <tr>
              <td><strong>Subtotal</strong></td>
              <td>{order?.productsPrice.currency}{order?.productsPrice.amount}</td>
            </tr>
            <tr>
              <td><strong>Descuento</strong></td>
              <td>- {order?.discountAmount.currency}{order?.discountAmount.amount}</td>
            </tr>
            <tr>
              <td><strong>Envío</strong></td>
              <td>{order?.shipmentAmount.currency}{order?.shipmentAmount.amount}</td>
            </tr>
            <tr className="total-row">
              <td>Total</td>
              <td>{order?.total.currency}{order?.total.amount}</td>
            </tr>
          </table>
        </Col>
        <Col className="text-center mt-5">
          <a href={order?.payment.payment_link} className="payment-button">PAGAR PEDIDO</a>
        </Col>
      </Row>
    </Container>
  )

}

export default CheckoutMakePayment