import {GET_AUTHOR} from '../actions/author'

const INITIAL_STATE = {
  author: null,
  isFetching: false,
  isSuccess: false,
  error: null,
}

export const getAuthor = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case `${GET_AUTHOR}_REJECTED`:
        return {
        ...state,
        error: action.payload,
        isFetching: false,
        isSuccess: false,
        }
    case `${GET_AUTHOR}_PENDING`:
        return {
        ...state,
        isFetching: true,
        error: null,
        isSuccess: false,
        }
    case `${GET_AUTHOR}_FULFILLED`:
        return {
        ...state,
        isFetching: false,
        author: action.payload.author,
        isSuccess: true,
        }
    case `${GET_AUTHOR}`:
        return {
        ...state,
        isFetching: action.payload.state,
        isSuccess: false,
        }
    default: 
        return state
    }
}

