import{
  LIST_ORDERS,
} from '../actions/order'
import { USER_LOGOUT } from '../actions/auth'

const INITIAL_STATE = {
  orders: [],
  currentPage : null,
  numberOfPages: null,
  isFetching: false,
  isSuccess: false,
  error: null,
}
  
export const listOrders = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case `${LIST_ORDERS}_REJECTED`:
      return {
        ...state,
        fetched: false,
        validate: false,
        error: action.payload,
        isSuccess: false,
      }
    case `${LIST_ORDERS}_PENDING`:
      return {
        ...state,
        isFetching: true,
        error: null,
        isSuccess: false,
      }
    case `${LIST_ORDERS}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        orders: action.payload.data,
        currentPage: action.payload.pagination.current_page,
        numberOfPages: action.payload.pagination.total_pages,
        isSuccess: true,
      }
    case `${LIST_ORDERS}`:
      return {
        ...state,
        isFetching: action.payload.state,
        isSuccess: false,
      }
    case `${USER_LOGOUT}`:
        return {...INITIAL_STATE};
    default: 
      return state
  }
}