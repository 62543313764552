import axios from 'axios'

export const FIND_MAIN_CATEGORIES = 'FIND_MAIN_CATEGORIES'
export const FIND_FOOTER_CATEGORIES = 'FIND_FOOTER_CATEGORIES'
export const FIND_TREE_CATEGORIES = 'FIND_TREE_CATEGORIES'

export const findMainCategories = () => ({
  type: FIND_MAIN_CATEGORIES,
  payload: axios.get('/categories/main')
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const findFooterCategories = () => ({
  type: FIND_FOOTER_CATEGORIES,
  payload: axios.get('/categories/footer')
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const findTreeCategories = () => ({
  type: FIND_TREE_CATEGORIES,
  payload: axios.get('/categories/tree')
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})