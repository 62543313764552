import { useDispatch } from 'react-redux'
import { addFavorite } from '../actions/favorite'
import { usePopup } from '../context/popup-context'

const useAddToFav = () => {
  const dispatch = useDispatch()
  const { setPopup } = usePopup()
    
  const addToFav = (productId, cartQuantity) => {
    dispatch(addFavorite(productId))
      .then(response => {
        setPopup('El producto fue agregado a favoritos correctamente!')
      })
  }

  return addToFav
}

export default useAddToFav