import {FIND_OFFER_PRODUCTS} from '../actions/product'

const INITIAL_STATE = {
  products: null,
  currentPage : null,
  numberOfPages: null,
  isFetching: false,
  isSuccess: false,
  error: null,
}

export const findOfferProducts = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case `${FIND_OFFER_PRODUCTS}_REJECTED`:
        return {
        ...state,
        error: action.payload,
        isFetching: false,
        isSuccess: false,
        }
    case `${FIND_OFFER_PRODUCTS}_PENDING`:
        return {
        ...state,
        isFetching: true,
        error: null,
        isSuccess: false,
        }
    case `${FIND_OFFER_PRODUCTS}_FULFILLED`:
        return {
        ...state,
        isFetching: false,
        products: action.payload.data,
        currentPage: action.payload.pagination.current_page,
        numberOfPages: action.payload.pagination.total_pages,
        isSuccess: true,
        }
    case `${FIND_OFFER_PRODUCTS}`:
        return {
        ...state,
        isFetching: action.payload.state,
        isSuccess: false,
        }
    default: 
        return state
    }
}

