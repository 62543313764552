import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { showOrder } from '../../actions/order'
import { Container, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import UserSideMenu from '../../components/user-side-menu'

const Order = props => {
	const [order, setOrder] = useState(null)
	const dispatch = useDispatch()

	useEffect(() => {
		let orderId = props.match.params.id
		dispatch(showOrder(orderId))
			.then(response => {
				let _order = response.value.order
				setOrder(_order)
			})
	}, [])

	return (
		<Container fluid className="inner-page desktop purchase max-container">
			<div className="breadcrumb">
				Inicio / MI CUENTA
			</div>
			<h3>Mi Perfil</h3>
			<Row className="ml-0 mr-0">
				<UserSideMenu />
				<Col lg={7} className="profile-actions">
					<div className="green-box">
						<h4>Atención</h4>
						<p className="disclaimer">Los siguientes datos no pueden ser modificados, son datos de facturación.</p>
						{!order ?
							<h5 style={{ border: 'none' }}>No se encontro el pedido seleccionado</h5>
							:
							<>
								<h5>Detalles del pedido</h5>
								<table>
									<tr>
										<td className="heading">Producto</td>
										<td className="heading">Total</td>
									</tr>
									{order.orderItems.map((orderItem, i) =>
										<tr key={i}>
											<td>{orderItem.productTitle}</td>
											<td>{orderItem?.productPrice.currency}{orderItem.productPrice.amount}</td>
										</tr>
									)}
									<tr>
										<td className="heading">Subtotal</td>
										<td>{order?.productsPrice.currency} {order?.productsPrice.amount}</td>
									</tr>
									<tr>
										<td className="heading">Descuento</td>
										<td>- {order?.discountAmount.currency} {order.discountAmount.amount}</td>
									</tr>
									<tr>
										<td className="heading">Envío</td>
										<td>{order.shipMethod}</td>
									</tr>
									<tr>
										<td className="heading">Total</td>
										<td>{order?.total.currency} {order.total.amount}</td>
									</tr>
								</table>

								{
									order.shipMethod == "Envío - Sucursal Correo"
										?
										<>
											<h5>Sucursal de envío</h5>
											{
												((order?.possible_shipping_offices?.offices ?? null) != null && order?.possible_shipping_offices?.offices.length > 0)
												?
													<table>
														<tr>
															<td className="heading">Sucursal</td>
															<td>{order?.possible_shipping_offices.offices[0].full_address}</td>
														</tr>
														<tr>
															<td className="heading">Localidad</td>
															<td>{order?.possible_shipping_offices.offices[0].localidad}, {order?.possible_shipping_offices.offices[0].provincia}</td>
														</tr>
														<tr>
															<td className="heading">Horario</td>
															<td>{order?.possible_shipping_offices.offices[0].horario_atencion}</td>
														</tr>
													</table>
												: <div>No pudimos cargar tu sucursal</div>
											}
										</>
										:
										<>
											<h5>Domicilio de envío</h5>
											<table>
												<tr>
													<td className="heading">Nombre</td>
													<td>{order?.orderShipAddress.name} {order?.orderShipAddress.last_name}</td>
												</tr>
												<tr>
													<td className="heading">Documento de identidad</td>
													<td>{order?.orderShipAddress.dni}</td>
												</tr>
												<tr>
													<td className="heading">Teléfono</td>
													<td>{order?.orderShipAddress.phone}</td>
												</tr>
												<tr>
													<td className="heading">E-mail</td>
													<td className="heading">{order?.orderShipAddress.email}</td>
												</tr>
												<tr>
													<td className="heading">Dirección</td>
													<td>{order?.orderShipAddress.street_ln_1} {order?.orderShipAddress.street_ln_2}</td>
												</tr>
												<tr>
													<td className="heading">Localidad / Ciudad</td>
													<td>{order?.orderShipAddress.city}</td>
												</tr>
												<tr>
													<td className="heading">Provincia / Estado</td>
													<td>{order?.orderShipAddress.province}</td>
												</tr>
												<tr>
													<td className="heading">Pais</td>
													<td className="heading">{order?.orderShipAddress.country}</td>
												</tr>
												<tr>
													<td className="heading">Código Postal</td>
													<td>{order?.orderShipAddress.zip_code}</td>
												</tr>
											</table>
										</>
								}
								<h5>Domicilio de facturación</h5>
								<table className='mb-5'>
									<tr>
										<td className="heading">Nombre</td>
										<td>{order?.orderBillAddress.name} {order?.orderBillAddress.last_name}</td>
									</tr>
									<tr>
										<td className="heading">Documento de identidad</td>
										<td>{order?.orderBillAddress.dni}</td>
									</tr>
									<tr>
										<td className="heading">Teléfono</td>
										<td>{order?.orderBillAddress.phone}</td>
									</tr>
									<tr>
										<td className="heading">E-mail</td>
										<td className="heading">{order?.orderBillAddress.email}</td>
									</tr>
									<tr>
										<td className="heading">Dirección</td>
										<td>{order?.orderBillAddress.street_ln_1} {order?.orderBillAddress.street_ln_2}</td>
									</tr>
									<tr>
										<td className="heading">Localidad / Ciudad</td>
										<td>{order?.orderBillAddress.city}</td>
									</tr>
									<tr>
										<td className="heading">Provincia / Estado</td>
										<td>{order?.orderBillAddress.province}</td>
									</tr>
									<tr>
										<td className="heading">Pais</td>
										<td className="heading">{order?.orderBillAddress.country}</td>
									</tr>
									<tr>
										<td className="heading">Código Postal</td>
										<td>{order?.orderBillAddress.zip_code}</td>
									</tr>
								</table>
								<div className="state">
									<p>El pedido <strong>#{order.id}</strong> se realizó el <strong>{moment(order.created_at).format('DD/MM/YYYY')}</strong> y su estado es <strong>{order.state}</strong>.</p>
								</div>
							</>
						}

					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default Order