import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchProducts } from '../actions/product'
import { Container, Row, Col } from 'react-bootstrap'
import SearchBreadcrum from '../components/search-breadcrum'
import SearchFilter from '../components/search-filter'
import ProductCard from '../components/product-card'
import { useLocation, useParams } from 'react-router'
import history from '../history'
import {Pagination} from '../components/pagination'
import useWindowDimensions from '../helpers/window-dimension'

const ProductsList = () => {
  const { products, isFetching, currentPage, numberOfPages} = useSelector(state => state.searchProducts)
  const [appliedFilters, setAppliedFilters] = useState({})
  const [show, setShow] = useState(2)
  const { search: queryString } = useLocation()
  const params = useParams()
  const dispatch = useDispatch()
  const myRef = useRef(null)
  const { width }  = useWindowDimensions() 

  useEffect(() => {
    calculateShow(window.innerWidth)
  },[])

  useEffect(() => {
    calculateShow(width)
  },[width])

  const calculateShow = (windowWidth) => {
    if(windowWidth < 475){
      setShow(2)
    } else if(windowWidth < 992){
      setShow(2)
    } else {
      setShow(4)  
    }
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(queryString)
    let filter = {
      q: searchParams.get('q'),
      editorial: searchParams.get('editorial'),
      autor: searchParams.get('autor'),
      formato: searchParams.get('formato'),
      idioma: searchParams.get('idioma'),
      precioMin: searchParams.get('precioMin'),
      precioMax: searchParams.get('precioMax'),
      ofertas: searchParams.get('ofertas'),
      page: searchParams.get('page'),
      orderBy: searchParams.get('orderBy'),
      category: params.category != undefined ? params.category : '',
    }
    Object.keys(filter).forEach((k) => filter[k] == null && delete filter[k])
    setAppliedFilters({...filter})   
  },[queryString,params])

  
  useEffect(() => {
    if(Object.keys(appliedFilters).length > 0){
      const {category, ...rest} = appliedFilters
      dispatch(searchProducts({...rest},category))
    }
  },[appliedFilters])


  const onAddFilter = filter => {
    // if(products.length > 0) {
      navigateProductsList({...appliedFilters, ...filter})
    // }
  }

  const onRemoveFilter = filterName => {
    let newFilters = { category: appliedFilters.category}
    if(filterName !== "all"){
      newFilters = {...appliedFilters}
      if(filterName === 'precio'){
        delete newFilters['precioMin']
        delete newFilters['precioMax']
      } else{
        delete newFilters[filterName]
      }
    } 
    navigateProductsList(newFilters)
  }

  const onCategorySelect = categorySlug => {
    let newFilters = {...appliedFilters, category: categorySlug}
    // navigateProductsList({category: categorySlug})
    navigateProductsList(newFilters)
  }

  const onChangeOrder = orderBy => {
    let newFilters = {...appliedFilters, orderBy}
    navigateProductsList(newFilters)
  }

  const paginationClick = pageNumber => {
    console.log('///////////////')
    console.log(pageNumber)
    navigateProductsList(appliedFilters, pageNumber)
  }

  const navigateProductsList = (filters, page = 1) => {
    console.log(']]]]]]]]]]]]')
    console.log(filters, page)
    const {category, ...rest} = filters
    let search = "";
    if(page > 1){
      search = "?" + new URLSearchParams({...rest, page}).toString()
    }else{
      delete rest['page']
      search = "?" + new URLSearchParams({...rest}).toString()
    }
    history.push({
      pathname: `/productos/${category}`,
      search
    })
  }

  const onChangeShow = (index) => {
    setShow(index)
  }
  
  return (
    <Container fluid className="product-search max-container">
      <Row ref={myRef}>
        <Col md={3}>
        <SearchFilter 
            handleSelectCategory={onCategorySelect}
            handleAddFilter={onAddFilter}
            appliedFilters={appliedFilters}/>
        </Col>
        <Col xs={12} md={9}>
        <SearchBreadcrum
            order={appliedFilters?.orderBy?? ''}
            show={show} 
            appliedFilters={appliedFilters}
            handleChangeShow={onChangeShow}
            handleChangeOrder={onChangeOrder} 
            handleRemoveFilter={onRemoveFilter}/>
          { 
            isFetching === true ?
              <h3 className="w-full mt-5 mb-5 pt-5 pb-5 border-0 text-center" style={{color :'#16B54C'}}>Cargando...</h3>
            :
              products != null && products.length > 0 ?
                <>
                  <Row xs={show} className='products-row-container'>
                    {products.map((e,i) => 
                      <Col key={i} className='products-col-container'>
                        <ProductCard product={e}/>
                      </Col>    
                    )}
                  </Row>
                  <Row>
                      <Pagination
                        items={Array.from(Array(numberOfPages).keys())} 
                        active={currentPage} 
                        onClick={paginationClick}
                        totalPages={numberOfPages}
                      />
                  </Row>
                </>
              :
              <Row>
                <Col>
                  <h4 className="text-center mt-5">No se encontraron productos</h4>
                </Col>
              </Row>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default ProductsList