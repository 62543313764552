import {SHOW_PRODUCT} from '../actions/product'

const INITIAL_STATE = {
  product: null,
  isFetching: false,
  isSuccess: false,
  error: null,
}

export const showProduct = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case `${SHOW_PRODUCT}_REJECTED`:
        return {
        ...state,
        error: action.payload,
        isFetching: false,
        isSuccess: false,
        }
    case `${SHOW_PRODUCT}_PENDING`:
        return {
        ...state,
        isFetching: true,
        error: null,
        isSuccess: false,
        }
    case `${SHOW_PRODUCT}_FULFILLED`:
        return {
        ...state,
        isFetching: false,
        product: action.payload.product,
        isSuccess: true,
        }
    case `${SHOW_PRODUCT}`:
        return {
        ...state,
        isFetching: action.payload.state,
        isSuccess: false,
        }
    default: 
        return state
    }
}

