import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'

const CurrencyDropdown = () => {
  const [currency, setCurrency] = useState('ARS')
  const [showDropdown, setShowDropdown] = useState(false)

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown) 
  }

  useEffect(() => {
    if(localStorage.getItem('entelequia-currency') != null){
      setCurrency(localStorage.getItem('entelequia-currency'))
    }
  },[])
  
  const handleSelectCurrency = currencyCode => {
    localStorage.setItem('entelequia-currency',currencyCode)
    setCurrency(currencyCode)
    toggleDropdown()
    window.location.reload()
  }

  return (
    <DropdownButton 
      id="dropdown-currency"
      title={currency}
      className="currency"
      onClick={() => toggleDropdown()}
      onBlur={() => { if(showDropdown) toggleDropdown()}}
      show={showDropdown}>
      <Dropdown.ItemText>
        <button onMouseDown={() => handleSelectCurrency('ARS')} translate="no">ARS</button>
      </Dropdown.ItemText>
      <Dropdown.ItemText>
        <button onMouseDown={() => handleSelectCurrency('USD')} translate="no">USD</button>
      </Dropdown.ItemText>
    </DropdownButton>
  )
}

export default CurrencyDropdown