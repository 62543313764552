import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import history from '../history'
import { Form, InputGroup, Button, FormControl } from 'react-bootstrap'
import SiteSearchSuggetions from './site-search-suggestions'
import { getSearchSuggestion } from '../actions/search-suggestion'
import { PUBLIC_URL } from '../helpers/domain'

const SiteSearch = () => {
    const [searchText, setSearchText] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [showSuggestions, setShowSuggestions] = useState(false)
    const dispatch = useDispatch()
    let publicUrl = PUBLIC_URL

    useEffect(()=> {
        if(searchText && searchText.length >= 3) {
            getSuggestions()
          } 
    },[searchText])

    const onSubmit = evt => {
        evt.preventDefault()
        history.push({pathname:"/productos", search:`?q=${searchText}`})
    }

    const getSuggestions = () => {
      dispatch(getSearchSuggestion(searchText))
        .then(response => {
          setSuggestions(response.value)
        })
    }

    const onFocus = () => setShowSuggestions(true)

    const onBlur = (evt) => setShowSuggestions(false)
        
    
    return (
      <Form className="w-100" onSubmit={(evt) => onSubmit(evt)}>
        <InputGroup className="search-box">
          <FormControl
            type="search"
            placeholder="Buscar por título, editorial, autor, palabra clave..."
            name="q"
            className="mr-2"
            value={searchText}
            aria-label="Search"
            autoComplete="off"
            onFocus={() => onFocus()}
            onBlur={(evt) => onBlur(evt)}
            onChange={(evt) => {setSearchText(evt.target.value)}}
            />
          <InputGroup.Append>
            <Button type="submit" className="search-button">
              <img src={`${publicUrl}/images/icons/search.png`} alt="Search"/>
            </Button>
          </InputGroup.Append>
          { searchText !== '' && showSuggestions === true && Object.keys(suggestions).length  > 0  &&
            <SiteSearchSuggetions products={suggestions.products} authors={suggestions.authors} brands={suggestions.brands}/>
          }
        </InputGroup>
      </Form>
    )
}

export default SiteSearch