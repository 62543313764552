import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {recoverPasswordValidation} from '../actions/auth'
import { Container, Row, Col } from 'react-bootstrap'

const RecoverPasswordValidation = () => { 
  const {search} = useLocation()
  const [error, setError] = useState()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    let urlParms = new URLSearchParams(search)
    let email = urlParms.get('email')
    let token = urlParms.get('token')
    if(email == null || token == null){
      setError('Verifique los datos ingresados')
    } else {
      dispatch(recoverPasswordValidation(email,token))
        .then(() => {
          history.push('/cuenta/resetear-clave')
        })
        .catch((error) => {
          setError('Error al intentar validar confirmación. Si el error persiste comuniquese con nostros. Gracias')
        })
    }
  }, [])
  
  return (
    <Container fluid className="inner-page connect max-container">
      <Row className="ml-0 mr-0 mt-5 mb-4">
        <Col lg={{ span: 8, offset: 2}} className="login">
            <div className="green-box">
                <h4 className='text-center'>Restaurar la clave de acceso</h4>
                { error ?
                    <h5 className='mt-5 mb-5' style={{color: 'red'}}>{error}</h5>
                  :
                    <h5 className='mt-5 mb-5'>Validando confirmación...</h5>
                }
            </div>        
        </Col>
      </Row>
    </Container>
  )
}

export default RecoverPasswordValidation