import axios from 'axios'

export const GET_TAG = 'GET_TAG'

export const getTag = (slug) => ({
  type: GET_TAG,
  payload: axios.get('/tags',{
    params: {
      uuid: localStorage.getItem('entelequia-cart-uuid'),
      currency: localStorage.getItem('entelequia-currency'),
      slug
    }
  })
  .then(response => response.data)
  .catch(error => Promise.reject(error))
})