import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { recoverPassword } from '../actions/auth'
import history from '../history'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Alert from '../components/alert'

const RecoverPassword = () => { 
  const [loginMessage, setLoginMessage] = useState(null)
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()

  const onFormSubmit = (evt) => {
    evt.preventDefault()
    dispatch(recoverPassword(email))
      .then(() => {
        history.push('/recuperar-clave-mail')
      }).catch((error) => {
        setLoginMessage(error.response.message)
      })
  }
  
  return (
    <Container fluid className="inner-page connect max-container">
      <Row className="ml-0 mr-0 mt-5 mb-4">
        <Col lg={{ span: 8, offset: 2}} className="login">
            <div className="green-box">
                <h4 className='text-center'>Restaurar la clave de acceso</h4>
                {loginMessage && 
                  <div className="w-full mb-4">
                    <Alert
                      color="bg-transparent border-red-500 text-red-500"
                      borderLeft
                      raised>
                      {loginMessage}
                    </Alert>
                  </div>
                }
                <p>Si olvidaste tu clave de acceso, completa el siguiente formulario con tu email, y te estaremos enviando un correo electrónico para que puedas restablecer tu contraseña. </p>
                <form onSubmit={onFormSubmit}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1"><img src="images/icons/envelop.svg" alt=""/></span>
                    </div>
                    <input 
                      type="email" 
                      name="email" 
                      className="form-control" 
                      placeholder="Correo eletrónico" 
                      value={email} 
                      onChange={(evt) => setEmail(evt.target.value)}
                      required />
                  </div>
                  <Button type="submit">Aceptar</Button>
                </form>
            </div>        
        </Col>
      </Row>
    </Container>
  )
}

export default RecoverPassword