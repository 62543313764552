import React from 'react'
import axios from 'axios'
import history from '../history'
import config from '../config/index'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { REACT_APP_API_URL } from './domain'

const { API_VERSION } = config;

export const initializeAxios = () => {
  const api_url = `${REACT_APP_API_URL}${API_VERSION}`
  
  axios.defaults.baseURL = api_url

  axios.interceptors.response.use(
    response => response,
    (error) => {
      if(!error.response){
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className='confirm-body'>
                <div className="text-lg mb-2 font-bold">Error</div>
                <p>Error al intentar conectar con el servidor. Si el problema persiste comuniquese con el area de soporte.</p>
                <div className="button-group">
                  <button className="btn btn-default btn-rounded bg-white hover:bg-red-100 text-red-900" onClick={onClose}>Cerrar</button>
                </div>
              </div>
            )
          },
          closeOnEscape: true,
          closeOnClickOutside: true,
        })
      } else {
        if (error.response.status === 401) {
          history.push({
            pathname: '/login',
            //state: { remove: true }
          });
        }
      }
      return Promise.reject(error)  
    }
  )

  let token = localStorage.getItem('entelequia-access-token-v2')
  if(token != null)
    addAxiosToken(token)
}

export const addAxiosToken = token => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
}