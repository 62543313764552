import React from 'react'
import { Row, Col } from 'react-bootstrap'

const ShopInfo = ({freeShippingAmount}) => 
  <Row className="shop-info"> 
    <Col sm={3} className="mb-3 mb-sm-0">
      <div className="box">
        <img src="images/icons/clock.png" className="img-fluid ml-2 mr-3" alt="Entrega"/>
        <h4>Entrega rápida</h4>
        <p>tu pedido en menos de 24 hs hábiles</p>
      </div>
    </Col>
    <Col sm={3} className="mb-3 mb-sm-0">
      <div className="box">
        <img src="images/icons/lock.png" className="img-fluid ml-2 mr-3" alt="Seguridad"/>
        <h4>Compras seguras</h4>
        <p>100% garantía en todas tus compras</p>
      </div>
    </Col>
    {
      (freeShippingAmount != null && freeShippingAmount != "") &&
        <Col sm={3} className="mb-3 mb-sm-0">
          <div className="box">
            <img src="images/icons/truck.png" className="img-fluid ml-0 mr-3 truck" alt="Envios"/>
            <h4>Envíos gratis Arg</h4>
            <p>para compras mayores a $ {Number(freeShippingAmount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</p>
          </div>
        </Col>
    }
    <Col sm={3} className="mb-3 mb-sm-0">
      <div className="box world">
        <img src="images/icons/world_ship.svg" className="img-fluid ml-0 mr-2" alt="Seguridad"/>
        <h4>Envíos a todo el mundo</h4>
        <p>vamos estés donde estés</p>
      </div>
    </Col>
  </Row>

export default ShopInfo