import { Container, Row, Col } from "react-bootstrap"
import {Link} from 'react-router-dom'

const Policies = () => {

  return (
    <Container fluid className="inner-page how-to-buy">
      <div className="breadcrumb pl-0"><Link to="/">Inicio</Link> / POLITICIAS DE PRIVACIDAD</div>
      <div className="title">
        <h3>Política de privacidad</h3>
      </div>
      <Row className="steps">
        <Col xs={12}>
          <p>Entelequia te informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter personal de los usuarios 
          y clientes que puedan ser recabados por la navegación o contratación de servicios a través del sitio Web.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Quiénes somos</h2>
          <p>La dirección de nuestra web es:  <a href="https://entelequia.com.ar/sobre-nosotros/">entelequia.com.ar</a></p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Principios aplicados en el tratamiento de datos</h2>
          <p>En el tratamiento de tus datos personales, Entelequia aplicará los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos:</p>
          <ul>
            <li><strong>Principio de licitud, lealtad y transparencia:</strong> Entelequia siempre requerirá el consentimiento para el tratamiento de tus datos personales que serán usados 
            para brindar un mejor servicio.</li>
            <li><strong>Principio de minimización de datos:</strong>&nbsp;El Titular te solicitará solo los datos estrictamente necesarios para el fin o los fines que los solicita.</li>
            <li><strong>Principio de integridad y confidencialidad:</strong>&nbsp;Tus datos serán tratados de tal manera que su seguridad, confidencialidad e integridad esté garantizada.
              <br/> Debes saber que Entelequia toma las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de sus usuarios por parte de terceros.</li>
          </ul> 
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Obtención de datos personales </h2>
          <p>Para navegar por www.entelequia.com.ar&nbsp; no es necesario que facilites ningún dato personal. Los casos en los que sí proporcionas tus datos personales son los siguientes:</p>
          <ul>
            <li>Al contactar a través de los formularios de contacto o enviar un correo electrónico.</li>
            <li>Al realizar un comentario en un artículo o página.</li>
            <li>Al inscribirte nuestro newsletter.</li>
            <li>Al realizar una compra en nuestra tienda</li>
          </ul>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2> Tus derechos </h2>
          <p>Entelequia &nbsp;te informa que sobre tus datos personales tienes derecho a:</p>
          <ul>
            <li>Solicitar el acceso a los datos almacenados.</li>
            <li>Solicitar una rectificación o la cancelación.</li>
            <li>Solicitar la limitación de su tratamiento.</li>
            <li>Oponerte al tratamiento.</li>
            <li>Solicitar la portabilidad de tus datos.</li>
          </ul>
          <p>El ejercicio de estos derechos es personal y por tanto debe ser ejercido directamente por el interesado, solicitándolo directamente a Entelequia, lo que significa que cualquier
           cliente, suscriptor o colaborador que haya facilitado sus datos en algún momento puede dirigirse Entelequia &nbsp;y pedir información sobre los datos que tiene almacenados y cómo
            los ha obtenido, solicitar la rectificación de los mismos, solicitar la portabilidad de sus datos personales, oponerse al tratamiento, limitar su uso o solicitar la cancelación
             de esos datos en los ficheros.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Finalidad del tratamiento de datos personales</h2>
          <p>Entelequia se compromete a utilizar los datos solicitados únicamente para brindar un buen servicio. Esto implica usar los respectivos datos de contacto para brindar información
           pertinente sobre el estado de pedidos o respuestas a consultas. Así como también el uso de los datos de envío para envíar los productos solicitados al domicilio informado.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2> Seguridad de los datos personales </h2>
          <p>Para proteger tus datos personales, Entelequia toma todas las precauciones razonables y sigue las mejores prácticas de la industria para evitar su pérdida, mal uso, acceso 
          indebido, divulgación, alteración o destrucción de los mismos.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2> Exactitud y veracidad de los datos personales </h2>
          <p>Te comprometes a que los datos facilitados Entelequia sean correctos, completos, exactos y vigentes, así como a mantenerlos debidamente actualizados.</p>
          <p>Como Usuario del sitio Web eres el único responsable de la veracidad y corrección de los datos que remitas al sitio exonerando Entelequia de cualquier responsabilidad
           al respecto.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2> Aceptación y consentimiento </h2>
          <p>Como Usuario del sitio Web declaras haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes el tratamiento de los mismos por 
          parte de Entelequia en la forma y para las finalidades indicadas en esta Política de Privacidad.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Qué datos personales recogemos y por qué los recogemos</h2>
          <h4>Comentarios</h4>
          <p>Cuando los visitantes dejan comentarios en la web, recopilamos los datos que se muestran en el formulario de comentarios, así como la dirección IP del visitante y la cadena 
          de agentes de usuario del navegador para ayudar a la detección de spam.</p>
          <p>Una cadena anónima creada a partir de tu dirección de correo electrónico (también llamada hash) puede ser proporcionada al servicio de Gravatar para ver si la estás usando.
          La política de privacidad del servicio Gravatar está disponible aquí: https://automattic.com/privacy/. Después de la aprobación de tu comentario, la imagen de tu perfil es visible
           para el público en el contexto de su comentario.</p>
          <h4>Medios</h4>
          <p>Si subes imágenes a la web deberías evitar subir imágenes con datos de ubicación (GPS EXIF) incluidos. Los visitantes de la web pueden descargar y extraer cualquier dato de
           localización de las imágenes de la web.</p>
          <h4>Formularios de contacto</h4>
          <h4>Cookies</h4>
          <p>Si dejas un comentario en nuestro sitio puedes elegir guardar tu nombre, dirección de correo electrónico y web en cookies. Esto es para tu comodidad, para que no tengas que 
          volver a rellenar tus datos cuando dejes otro comentario. Estas cookies tendrán una duración de un año.</p>
          <p>Si tienes una cuenta y te conectas a este sitio, instalaremos una cookie temporal para determinar si tu navegador acepta cookies. Esta cookie no contiene datos personales
           y se elimina al cerrar el navegador.</p>
          <p>Cuando inicias sesión, también instalaremos varias cookies para guardar tu información de inicio de sesión y tus opciones de visualización de pantalla. Las cookies de inicio de
           sesión duran dos días, y las cookies de opciones de pantalla duran un año. Si seleccionas «Recordarme», tu inicio de sesión perdurará durante dos semanas. Si sales de tu cuenta,
            las cookies de inicio de sesión se eliminarán.</p>
          <p>Si editas o publicas un artículo se guardará una cookie adicional en tu navegador. Esta cookie no incluye datos personales y simplemente indica el ID del artículo que acabas
           de editar. Caduca después de 1 día.</p>
          <h4>Contenido incrustado de otros sitios web</h4>
          <p>Los artículos de este sitio pueden incluir contenido incrustado (por ejemplo, vídeos, imágenes, artículos, etc.). El contenido incrustado de otras web se comporta exactamente
           de la misma manera que si el visitante hubiera visitado la otra web.</p>
          <p>Estas web pueden recopilar datos sobre ti, utilizar cookies, incrustar un seguimiento adicional de terceros, y supervisar tu interacción con ese contenido incrustado, incluido
           el seguimiento de tu interacción con el contenido incrustado si tienes una cuenta y estás conectado a esa web.</p>
          <h4>Analítica</h4>
          <h4>Cuánto tiempo conservamos tus datos</h4>
          <p>Si dejas un comentario, el comentario y sus metadatos se conservan indefinidamente. Esto es para que podamos reconocer y aprobar comentarios sucesivos automáticamente en lugar
           de mantenerlos en una cola de moderación.</p>
          <p>De los usuarios que se registran en nuestra web (si los hay), también almacenamos la información personal que proporcionan en su perfil de usuario. Todos los usuarios pueden ver,
           editar o eliminar su información personal en cualquier momento (excepto que no pueden cambiar su nombre de usuario). Los administradores de la web también pueden ver y editar esa
            información.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Qué derechos tienes sobre tus datos</h2>
          <p>Si tienes una cuenta o has dejado comentarios en esta web, puedes solicitar recibir un archivo de exportación de los datos personales que tenemos sobre ti, incluyendo cualquier
           dato que nos hayas proporcionado. También puedes solicitar que eliminemos cualquier dato personal que tengamos sobre ti. Esto no incluye ningún dato que estemos obligados a 
           conservar con fines administrativos, legales o de seguridad.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Dónde enviamos tus datos</h2>
          <p>Los comentarios de los visitantes puede que los revise un servicio de detección automática de spam.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Lo que recopilamos y almacenamos con tu compra</h2>
          <h4>Mientras visitas nuestro sitio realizaremos el seguimiento de:</h4>
          <p>Los productos que has visto: usaremos esto para, por ejemplo, mostrarte productos que has visto recientemente<br/>
            Ubicación, dirección IP y tipo de navegador: usaremos esto para propósitos como estimar impuestos y costes de envío<br/>
            Dirección de envío: te pediremos que facilites esto para que podamos, por ejemplo, estimar el coste del envío antes de que hagas un pedido, ¡y enviarte el pedido!<br/>
            También usamos cookies para realizar el seguimiento de los contenidos del carrito mientras navegas por nuestro sitio.</p>
          <p>Observación: puede que quieras detallar tu política de cookies, y enlazar desde aquí a esta sección.</p>
          <p>Cuando nos compras algo te pedimos que facilites información que incluye tu nombre, dirección de facturación, dirección de envío, dirección de correo electrónico, 
          número de teléfono, detalles de la tarjeta de crédito/pago e información adicional opcional como el nombre de usuario y la contraseña. Usaremos esta información para propósitos
            como:</p>
          <p>Enviarte información sobre tu cuenta y pedido<br/>
            Responder a tus solicitudes, incluidos reembolsos y reclamaciones<br/>
            Procesar pagos y evitar el fraude<br/>
            Configurar tu cuenta para nuestra tienda<br/>
            Cumplir con cualquier obligación legal que tengamos, como cálculos de impuestos<br/>
            Mejorar las ofertas de nuestra tienda<br/>
            Enviarte mensajes promocionales, si eliges recibirlos<br/>
            Si creas una cuenta almacenaremos tu nombre, dirección, correo electrónico y número de teléfono, lo que usaremos para el proceso de pago y futuros pedidos.</p>
          <p>Normalmente almacenamos información sobre ti durante el tiempo que necesitemos la información para los propósitos para los que la recopilamos y usamos, y no estemos legalmente
           obligados a seguir guardándola. Por ejemplo, almacenaremos información de los pedidos durante XXX años para propósitos de impuestos y contabilidad. Esto incluye tu nombre,
            dirección de correo electrónico y direcciones de facturación y envío.</p>
          <p>También almacenamos comentarios o valoraciones, si eliges dejarlos.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Quién de nuestro equipo tiene acceso</h2>
          <p>Los miembros de nuestro equipo tienen acceso a la información que nos ofreces. Por ejemplo, los administradores y gestores de tienda pueden acceder:</p>
          <p>La información de pedidos, como qué se compró, cuándo se compró y dónde se debería enviar, y<br/> 
           La información de cliente, como tu nombre, dirección de correo electrónico, y la información de facturación y envío.<br/>
           Los miembros de nuestro equipo tienen acceso a esta información para ayudar a completar pedidos, procesar reembolsos y darte soporte.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Lo que compartimos con otros</h2>
          <p>En esta sección deberías apuntar con quién estás compartiendo datos, y con qué objetivo. Esto podría incluir, pero no está solo limitado a ello, análisis, marketing,
           pasarelas de pago, proveedores de envío, e incrustados de terceros.</p>
          <p>Compartimos información con otros, que nos ayudan a ofrecer nuestros pedidos y gestionar servicios para ti.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2>Pagos</h2>
          <p>En esta subsección deberías apuntar qué otros procesadores de pago externos estás usando para admitir pagos en tu tienda, ya que puede que manejen datos de los clientes.
           Hemos incluido PayPal como ejemplo, pero deberías borrarlo si no estás usando PayPal.</p>
          <p>Aceptamos pagos mediante PayPal. Al procesar pagos algunos de tus datos se pasarán a PayPal, incluida la información requerida para procesar o dar soporte al pago, tales 
          como el total de la compra y la información de facturación.</p>
          <p>Por favor, revisa la <a href="https://www.paypal.com/es/webapps/mpp/ua/privacy-full">política de privacidad de PayPal</a> para más detalles.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2> Lo que recopilamos y almacenamos con tu registro a la web y a nuestros newsletters </h2>
          <p>Si te has suscrito a nuestro boletín o si eres un miembro de nuestro sitio (puedes conectarte) o si has comprado en nuestro sitio, hay bastantes posibilidades de que recibas
           correos electrónicos de nuestra parte.</p>
          <p>Para enviarte correos, utilizamos el nombre y la dirección de correo electrónico que nos diste. Nuestro sitio también registra la dirección IP que utilizaste cuando te
           registraste en el servicio para prevenir el abuso del sistema.</p>
          <p>Esta web puede enviar correos a través del servicio de envíos de MailPoet. Este servicio nos permite monitorizar las aperturas y clics en nuestros correos. Utilizamos esta
           información para mejorar el contenido de nuestros boletines.</p>
          <p>No monitorizamos ninguna otra información que te identifique fuera de este sitio excepto tu dirección de correo electrónico.</p>
          <p>Cookie name: mailpoet_revenue_tracking<br/>
            Caducidad de la cookie: 14 días.<br/>
            Descripción de la cookie: El propósito de esta cookie es el seguimiento de qué boletín enviado por tu web ha recibido un clic y una compra posterior en tu tienda de WooCommerce.</p>
          <p>Cookie name: mailpoet_abandoned_cart_tracking<br/>
          Caducidad de la cookie: 3.650 días.<br/>
          Descripción de la cookie: El propósito de esta cookie es el seguimiento del usuario que ha abandonado el carrito en tu tienda de WooCommerce para poder enviarle un boletín de 
          carrito abandonado con MailPoet.</p>
          <p>Nota: Al usuario debe darse la posibilidad de aceptar y ser un suscriptor confirmado.</p>
        </Col>
        <Col xs={12} lg={12} className="item">
          <h2> Aceptación y consentimiento </h2>
          <p>Como Usuario del sitio Web declaras haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptas y consientes el tratamiento de los mismos
           por parte de Entelequia en la forma y para las finalidades indicadas en esta Política de Privacidad.</p>
        </Col>
      </Row>
    </Container>  
  )
}

export default Policies