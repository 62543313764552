import { GET_HOME_CONTENT } from '../actions/home'

const INITIAL_STATE = {
  isFetching: false,
  isSuccess: false,
  error: null,
}

export const getHomeContent = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case `${GET_HOME_CONTENT}_REJECTED`:
        return {
        ...state,
        error: action.payload,
        isFetching: false,
        isSuccess: false,
        }
    case `${GET_HOME_CONTENT}_PENDING`:
        return {
        ...state,
        isFetching: true,
        error: null,
        isSuccess: false,
        }
    case `${GET_HOME_CONTENT}_FULFILLED`:
        return {
        ...state,
        isFetching: false,
        isSuccess: true,
        }
    case `${GET_HOME_CONTENT}`:
        return {
        ...state,
        isFetching: action.payload.state,
        isSuccess: false,
        }
    default: 
        return state
    }
}

