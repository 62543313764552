import { combineReducers } from 'redux'
import { showCart } from './cart'
import { showProduct } from './show-product'
import { getAuthor } from './author'
import { getTag } from './tag'
import { findOfferProducts } from './offers'
import { findLatestProducts } from './latest'
import { findPresaleProducts } from './presale'
import { findSpecialOffers } from './special-offers'
import { searchProducts } from './search-products'
import { findRecommendedProducts } from './recommended'
import { getHomeContent } from './home'
import {listOrders} from './orders-list'
import { listFavorites } from './favorites-list'
import { showClientProfile } from './profile-show'

const rootReducer = combineReducers({  
  getHomeContent,
  getAuthor,
  getTag,
  searchProducts,
  showProduct,
  findOfferProducts,
  findLatestProducts,
  findPresaleProducts,
  findSpecialOffers,
  findRecommendedProducts,
  showCart,
  showClientProfile,
  listOrders,
  listFavorites,
})

export default rootReducer