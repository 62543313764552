import{
SHOW_CART,
} from '../actions/cart'

const INITIAL_STATE = {
  cart: null,
  isFetching: false,
  isSuccess: false,
  error: null,
}

export const showCart = (state = INITIAL_STATE, action) => {
    switch(action.type){
    case `${SHOW_CART}_REJECTED`:
        return {
        ...state,
        error: action.payload,
        isFetching: false,
        isSuccess: false,
        }
    case `${SHOW_CART}_PENDING`:
        return {
        ...state,
        isFetching: true,
        error: null,
        isSuccess: false,
        }
    case `${SHOW_CART}_FULFILLED`:
        return {
        ...state,
        isFetching: false,
        cart: action.payload,
        isSuccess: true,
        }
    case `${SHOW_CART}`:
        return {
        ...state,
        isFetching: action.payload.state,
        isSuccess: false,
        }
    default: 
        return state
    }
}