import React from 'react'
import { Col, Container, Row } from "react-bootstrap"

const NewsletterThanks = () => {

    return (
        <Container className="inner-page newsletter-thanks" style={{paddingBottom: '80px', paddingTop: '100px'}}>
            <Row>
                <Col xs={12}>
                    <h3 className="text-center" style={{border: 'none'}}>¡Muchas Gracias!</h3>
                    <p className="text-center">Tu suscripción ya fue confirmada.</p>
                    <h4 className="text-center" style={{marginTop: '60px'}}>Tú cupon es:</h4>
                    <h4 className="text-center" style={{border: '4px solid green', padding: '10px', maxWidth: '300px', margin: '20px auto 0', fontWeight: 'bold'}}>4URHlN2kJf</h4>
                </Col>
            </Row>
        </Container>
    )

}

export default NewsletterThanks