import React, { useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {listFavorites, removeFavorite} from '../../actions/favorite'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import UserSideMenu from '../../components/user-side-menu'
import { Pagination } from '../../components/pagination'
import useAddToCart from '../../hook/add-to-cart'
import ButtonLoader from '../../components/button-loader'
import { PUBLIC_URL, REACT_APP_API_PUBLIC_FOLDER } from '../../helpers/domain'

const Favorite = () => {
  const { favorites, isFetching, currentPage, numberOfPages } = useSelector(state => state.listFavorites)
  const dispatch = useDispatch()
  let publicUrl = PUBLIC_URL
  const [isAddingToCart, addToCart ] = useAddToCart()
  let apiPublicFolder = REACT_APP_API_PUBLIC_FOLDER + 'storage/'
  
  useEffect(() => {
    dispatch(listFavorites())
  }, [])

  const onFavoriteRemove = productID => {
    dispatch(removeFavorite(productID))
      .then(() => {
        dispatch(listFavorites())
      })
  }

  const paginationClick = (pageNumber) => {
    dispatch(listFavorites(pageNumber))
  }

  return (
    <Container fluid className="inner-page desktop favorites max-container">
      <div className="breadcrumb">
          Inicio / MI CUENTA
      </div>
      <h3>Mi Perfil</h3>
      <Row className="ml-0 mr-0">
        <UserSideMenu />
        <Col lg={7} className="profile-actions">
          <div className="green-box">
            <h4>MIS FAVORITOS</h4>
            {
              isFetching?
                <h5 className='text-center mt-5 mb-5'>Cargando...</h5>
            :
              favorites.length ?
                <>
                  {  
                    favorites.map((f,i) => 
                      <Row key={i} className="fav-item">
                        <Col lg={3}>
                          { f.product.images.length === 0 ?
                            <img  src={`${publicUrl}/images/nofoto.png`} alt="1" className="img-fluid" />
                          :
                          <img  src={`${apiPublicFolder}/${f.product.images[0].path}`} alt="1" className="img-fluid" />
                          }
                        </Col>
                        <Col lg={6} className="fav-detail">
                          <p className="fav-title">{f.product.title}</p>
                          <p className="fav-price">Total: {f.product.price.currency} {f.product.price.amount}</p>
                          <p className="fav-stock">Stock: {f.product.stock}</p>
                        </Col>
                        <Col lg={3} className="fav-actions">
                          <Link to={`/producto/${f.product.slug}`} className="see-detail">Ver producto</Link>
                          <Button className="remove-favorite" onClick={() => onFavoriteRemove(f.id)}>Quitar de fav</Button>
                          <ButtonLoader 
                            isLoading={isAddingToCart}
                            loadingMessage="Agregando..."
                            type="button" 
                            className="add-to-cart see-detail"
                            onClick={() => addToCart(f.product.id,1)}>
                              Añadir al carrito
                          </ButtonLoader>
                        </Col>
                      </Row>
                    )
                  }
                  <Pagination
                    items={Array.from(Array(numberOfPages).keys())} 
                    active={currentPage} 
                    onClick={paginationClick}
                    totalPages={numberOfPages}/>
                </>
              :
                <Row>
                  <Col xs={12}>
                    <h5 className="text-center mt-4 mb-4">No hay productos agregados a favoritos</h5>
                  </Col>
                </Row>
            }
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Favorite