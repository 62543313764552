import React, {useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import history from '../history'
import moment from 'moment'
import ReactPixel from 'react-facebook-pixel'

const CheckoutComplete = props => { 
  const [order, setOrder] = useState(null)
  const [bankData, setBankData] = useState(null)


  useEffect(() => {
    let state = { ...props.location.state }
    if(state.order == null){
      history.push('/cuenta/mis-pedidos')
    } else{
      setOrder(state.order)
      delete state.order
      if(state.bankData){
        setBankData(state.bankData)
        delete state.bankData
      }
      history.replace({ ...history.location, state })
      window.gtag('event', 'conversion', { 'send_to': 'AW-10779232385/VZpmCL_C39EDEIGR-JMo', 'transaction_id': '' }); 
    }
  }, [])

  useEffect(() =>{
    if(order){
      let productsAndQuantity = order.orderItems.map(item => ({'id': item.productId, 'quantity': item.quantity})) 
      ReactPixel.init('261944151355410');
      ReactPixel.track('Purchase', { 
        value: order?.total.amount, 
        currency: order?.total.currency, 
        contents: productsAndQuantity, 
        content_type: 'product'});

        try{
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'purchase',
            transaction_id: order?.id,
            value: order?.total.amount,
            currency: order?.total.currency,
            items: order?.orderItems?.map((product,i) => ({
              id: product.id,
              name: product.productTitle,
              price: product.productPrice.amount,
              quantity: product.quantity
            }))
          });

        }catch(e){
          console.log(e)
        }

    }
  }, [order])
  
  return (
    <Container fluid className="inner-page checkout-payment complete max-container">
      <div className="breadcrumb pl-0">
        Inicio / CARRITO DE COMPRAS / <span> CHECKOUT</span>
      </div>
      <h3>PEDIDO <strong>RECIBIDO</strong></h3>
      {bankData != null && 
        <>
          <p className="message">Gracias por tu pedido. Por favor realiza una transferencia a la siguiente cuenta:</p>
          <Row>
            <Col xs={12} className="green-box" style={{backgroundColor : "#d5f7e0", marginBottom : "30px"}}>
              <h4 className="mt-4">DATOS PARA LA TRANSFERENCIA BANCARIA</h4>
              <h5 className="mt-2 mb-3">Agregar número de pedido en la descripción de la transferencia</h5>
              <table>
                <tr>
                  <td><strong>Monto a transferir</strong></td>
                  <td><strong>{order?.total.currency} {order?.total.amount}</strong></td>
                </tr>
                <tr>
                  <td><strong>Banco</strong></td>
                  <td><strong>{bankData?.bank}</strong></td>
                </tr>
                <tr>
                  <td><strong>Tipo de cuenta</strong></td>
                  <td>{bankData?.account_type}</td>
                </tr>
                <tr>
                  <td><strong>Nro. de cuenta</strong></td>
                  <td>{bankData?.account_number}</td>
                </tr>
                <tr>
                  <td><strong>Titular</strong></td>
                  <td>{bankData?.owner}</td>
                </tr>
                <tr>
                  <td><strong>CUIT</strong></td>
                  <td>{bankData?.cuit}</td>
                </tr>
                <tr>
                  <td><strong>CBU</strong></td>
                  <td>{bankData?.cbu}</td>
                </tr>
                <tr>
                  <td><strong>Alias</strong></td>
                  <td>{bankData?.alias}</td>
                </tr>
              </table>
            </Col>
          </Row>
        </>
      }
      
      {bankData == null && <p className="message">Gracias. Tu pedido ha sido recibido</p>}
      
      <Row className="summary">
        <Col>
          <p className="title">Número de pedido:</p>
          <p>{order?.id}</p>
        </Col>
        <Col>
          <p className="title">Fecha:</p>
          <p>{order && moment(order.created_at).format('DD/MM/YYYY')}</p>
        </Col>
        <Col>
          <p className="title">E-mail:</p>
          <p>{order?.orderBillAddress.email}</p>
        </Col>
        <Col>
          <p className="title">Total:</p>
          <p>{order?.total.currency} {order?.total.amount}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="green-box">
          <h4 className='mt-2'>DETALLES DEL PEDIDO</h4>
          <table>
            <tr>
              <td><strong>Producto</strong></td>
              <td><strong>Total</strong></td>
            </tr>
            { order?.orderItems?.map((e,i) => 
              <tr key={i}>
                <td>{e.productTitle}</td>
                <td>{e?.productPrice.currency}{e.productPrice.amount}</td>
              </tr>
            )}
            <tr>
              <td><strong>Subtotal</strong></td>
              <td>{order?.productsPrice.currency}{order?.productsPrice.amount}</td>
            </tr>
            <tr>
              <td><strong>Descuento</strong></td>
              <td>- {order?.discountAmount.currency}{order?.discountAmount.amount}</td>
            </tr>
            <tr>
              <td><strong>Envío</strong></td>
              <td>{order?.shipmentAmount.currency}{order?.shipmentAmount.amount}</td>
            </tr>
            <tr className="total-row">
              <td>Total</td>
              <td>{order?.total.currency}{order?.total.amount}</td>
            </tr>
          </table>


          {
              order?.shipMethod == "Envío - Sucursal Correo"
                ?
                <>
                  <h5>Sucursal de envío</h5>
                  {
                    ((order?.possible_shipping_offices?.offices ?? null) != null && order?.possible_shipping_offices?.offices.length > 0)
                    ?
                      <table>
                        <tr>
                          <td className="heading">Sucursal</td>
                          <td>{order?.possible_shipping_offices.offices[0].full_address}</td>
                        </tr>
                        <tr>
                          <td className="heading">Localidad</td>
                          <td>{order?.possible_shipping_offices.offices[0].localidad}, {order?.possible_shipping_offices.offices[0].provincia}</td>
                        </tr>
                        <tr>
                          <td className="heading">Horario</td>
                          <td>{order?.possible_shipping_offices.offices[0].horario_atencion}</td>
                        </tr>
                      </table>
                    : <div>No pudimos cargar tu sucursal</div>
                  }
                </>
                :
                <>
                  <h4 className="mt-4 mb-3">DOMICILIO DE ENVÍO</h4>
                  <table>
                    <tr>
                      <td><strong>Nombre</strong></td>
                      <td><strong>{order?.orderShipAddress.name} {order?.orderShipAddress.last_name}</strong></td>
                    </tr>
                    <tr>
                      <td><strong>Documento de identidad</strong></td>
                      <td>{order?.orderShipAddress.dni}</td>
                    </tr>
                    <tr>
                      <td><strong>Télefono</strong></td>
                      <td>{order?.orderShipAddress.phone}</td>
                    </tr>
                    <tr>
                      <td><strong>E-mail</strong></td>
                      <td>{order?.orderShipAddress.email}</td>
                    </tr>
                    <tr>
                      <td><strong>Dirección</strong></td>
                      <td>{order?.orderShipAddress.street_ln_1} {order?.orderShipAddress.street_ln_2}</td>
                    </tr>
                    <tr>
                      <td><strong>Localidad / Ciudad</strong></td>
                      <td>{order?.orderShipAddress.city}</td>
                    </tr>
                    <tr>
                      <td><strong>Provincia / Estado</strong></td>
                      <td>{order?.orderShipAddress.province}</td>
                    </tr>
                    <tr>
                      <td><strong>Pais</strong></td>
                      <td>{order?.orderShipAddress.country}</td>
                    </tr>
                    <tr>
                      <td><strong>Código Postal</strong></td>
                      <td>{order?.orderShipAddress.zip_code}</td>
                    </tr>
                  </table>
                </>
          }

          <h4 className="mt-4 mb-3">DOMICILIO DE FACTURACIÓN</h4>
          <table>
            <tr>
              <td><strong>Nombre</strong></td>
              <td><strong>{order?.orderBillAddress.name} {order?.orderBillAddress.last_name}</strong></td>
            </tr>
            <tr>
              <td><strong>Documento de identidad</strong></td>
              <td>{order?.orderBillAddress.dni}</td>
            </tr>
            <tr>
              <td><strong>Télefono</strong></td>
              <td>{order?.orderBillAddress.phone}</td>
            </tr>
            <tr>
              <td><strong>E-mail</strong></td>
              <td>{order?.orderBillAddress.email}</td>
            </tr>
            <tr>
              <td><strong>Dirección</strong></td>
              <td>{order?.orderBillAddress.street_ln_1} {order?.orderBillAddress.street_ln_2}</td>
            </tr>
            <tr>
              <td><strong>Localidad / Ciudad</strong></td>
              <td>{order?.orderBillAddress.city}</td>
            </tr>
            <tr>
              <td><strong>Provincia / Estado</strong></td>
              <td>{order?.orderBillAddress.province}</td>
            </tr>
            <tr>
              <td><strong>Pais</strong></td>
              <td>{order?.orderBillAddress.country}</td>
            </tr>
            <tr>
              <td><strong>Código Postal</strong></td>
              <td>{order?.orderBillAddress.zip_code}</td>
            </tr>
          </table>
        </Col>
      </Row>
    </Container>
  )

}

export default CheckoutComplete