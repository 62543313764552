import ProductCard from './product-card'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

export const Carousel = ({products}) => {
  const responsive = {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    992: {
      items: 4,
    },
    1200: {
      items: 5,
    }
  }

  return (
    products?.length > 0 &&
      <OwlCarousel className='owl-theme' margin={10} loop dots={true} nav={true} responsive={responsive}>
        { 
          products.map((e,i) => <ProductCard key={i} product={e} className="item"/> ) 
        }
      </OwlCarousel>
  )

}
