import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showClientProfile, updateClientAccount } from '../../actions/client'
import { Container, Row, Col, Form, FormGroup, Button, FormCheck } from 'react-bootstrap'
import UserSideMenu from '../../components/user-side-menu'
import { useSelector } from 'react-redux'

const Profile = () => {
  const {profile} = useSelector(state => state.showClientProfile)
  const [profileForm, setProfileForm] = useState({
    name: '',
    surname: '',
    username: '',
    email: '',
    password: '',
    new_password: '',
    new_password_confirmation: '',
    image: '',
    newsletterRegistration: false,
  })
  const dispatch = useDispatch()

  useEffect(() => {
    setProfileForm({...profile})
  },[profile])

  const handleInputChange = ( e  ) => {
    const { name, value } = e.target
    setProfileForm({...profileForm, [name]: value})
  }

  const handleFile = e => {
    setProfileForm({ ...profileForm, image: e.target.files[0] });
  };

  const onSubmit = (evt) => {
    evt.preventDefault()
    let formData = new FormData()
    formData.append('name',profileForm.name)
    formData.append('surname',profileForm.surname)
    formData.append('username',profileForm.username)
    formData.append('email',profileForm.email)
    formData.append('password',profileForm.password)
    formData.append('new_password',profileForm.new_password)
    formData.append('new_password_confirmation',profileForm.new_password_confirmation)
    formData.append('image',profileForm.image)
    formData.append('newsletterRegistration', profileForm.newsletterRegistration)
    formData.append('_method','PUT')
    dispatch(updateClientAccount(formData))
      .then(() => {
        dispatch(showClientProfile())
      })
  }

  return (
    <Container fluid className="inner-page desktop profile max-container">
      <div className="breadcrumb">
          Inicio / MI CUENTA
      </div>
      <h3>Mi Perfil</h3>
      <Row className="ml-0 mr-0">
        <UserSideMenu />
        <Col lg={7} className="profile-actions">
          <div className="green-box">
            <h4>Editar datos de usuario</h4>
            <Form onSubmit={onSubmit}>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Imágen</Form.Label>
                    <Form.Control type="file" onChange={handleFile}  />
                  </Form.Group>
                  <p>*Tamaño máximo de subida: 8192KB. Archivos permitidos: jpg jpeg png gif</p>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label htmlFor="name">Nombre</Form.Label>
                    <Form.Control type="text" name="name" value={profileForm.name} onChange={e => handleInputChange(e)} required/>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label htmlFor="surname">Apellido</Form.Label>
                    <Form.Control type="text" name="surname" value={profileForm.surname}  onChange={e => handleInputChange(e)} required/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Form.Label htmlFor="username">Nombre visible</Form.Label>
                    <Form.Control type="text" name="username" value={profileForm.username}  onChange={e => handleInputChange(e)} required />
                  </FormGroup>
                  <p>*Así será como se mostrará tu nombre en la sección de tu cuenta y en las valoraciones</p>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label htmlFor="email">Dirección de correo electrónico</Form.Label>
                    <Form.Control type="email" name="email" value={profileForm.email} onChange={e => handleInputChange(e)} required/>
                  </FormGroup>
                </Col>
              </Row>
              <h4 className="title-two">Cambio de contraseña</h4>
              <Row>
               <Col xs={6}>
                  <FormGroup>
                    <Form.Label htmlFor="password">Contraseña actual</Form.Label>
                    <Form.Control type="password" name="password" value={profileForm.password} onChange={e => handleInputChange(e)}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Form.Label htmlFor="new_password">Nueva contraseña</Form.Label>
                    <Form.Control type="password" name="new_password"  value={profileForm.new_password} onChange={e => handleInputChange(e)}/>
                  </FormGroup>
                  <p>*Déjalos en blanco para no cambiarla</p>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label htmlFor="new_password_confirmation">Confirmar nueva contraseña</Form.Label>
                    <Form.Control type="password" name="new_password_confirmation" value={profileForm.new_password_confirmation} onChange={e => handleInputChange(e)}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormCheck className="newsletter"> 
                    <input 
                      type="checkbox" 
                      name="newsletterRegistration"
                      className="form-check-input"
                      checked={profileForm.newsletterRegistration? true: false}
                      onChange={e => setProfileForm({...profileForm, newsletterRegistration: !profileForm.newsletterRegistration})}  
                      />
                    <label className="form-form-check-label green-text">Quiero suscribirme para recibir todas las novedades y promociones de Entelequia por mail mediante el newsletter.</label>
                  </FormCheck> 
                </Col>
              </Row>
              <Button variant="primary" type="submit">
                Guardar Cambios
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Profile