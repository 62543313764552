import React from 'react'
import { Route } from 'react-router-dom'
import Address from '../pages/account/address'
import Desktop from '../pages/account/desktop'
import Favorite from '../pages/account/favorite'
import Order from '../pages/account/order'
import Orders from '../pages/account/orders'
import Profile from '../pages/account/profile'
import RenewPassword from '../pages/account/renew-password'
import Checkout from '../pages/checkout'
import CheckoutComplete from '../pages/checkout-complete'
import CheckoutMakePayment from '../pages/checkout-make-payment'
import CheckoutProcessPayment from '../pages/checkout-process-payment'
import CheckoutGetnet from '../pages/checkout-getnet'

const UserRoutes = () => (
  <>
    <Route exact path="/cuenta/" component={Desktop}/>
    <Route path="/cuenta/mis-pedidos" component={Orders}/>
    <Route path="/cuenta/pedido/:id" component={Order}/>
    <Route path="/cuenta/favoritos" component={Favorite}/>
    <Route path="/cuenta/perfil" component={Profile}/>
    <Route path="/cuenta/direccion" component={Address}/>
    <Route path="/cuenta/resetear-clave" component={RenewPassword}/>
    <Route path="/cuenta/checkout" component={Checkout}/>
    <Route path="/cuenta/checkout-make-payment" component={CheckoutMakePayment}/>
    <Route path="/cuenta/checkout-getnet" component={CheckoutGetnet}/>
    <Route path="/cuenta/checkout-process-payment/:type/:status" component={CheckoutProcessPayment}/>
    <Route path="/cuenta/checkout-complete" component={CheckoutComplete}/>
  </>
)

export default UserRoutes