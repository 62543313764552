import React from 'react'
import history from '../history'


const SiteSearchSuggetions = ({products, authors, brands}) => {

    const productsOptions = products?.map((p) => 
        <li key={p.id}>
            <button onMouseDown={() => {history.push(`/producto/${p.slug}`)}}>
                {p.title.length > 50 ? p.title.substring(0,50)+'...' : p.title}
            </button>
        </li>
    )

    const authorsOptions = authors?.map((a,key) => 
        <li key={key}>
            <button onMouseDown={() => {history.push(`/autores/${a.slug}`)}}>
                {a.name.length > 50 ? a.name.substring(0,50)+'...' : a.name}
            </button>
        </li>
    )

    const brandsOptions = brands?.map((a,key) => 
        <li key={key}>
            <button onMouseDown={() => {history.push(`/productos/?editorial=${a.slug}`)}}>
                {a.name.length > 50 ? a.name.substring(0,50)+'...' : a.name}
            </button>
        </li>
    )

    return <ul>
        {
            products.length > 0 &&
            <>
                <li><strong>Productos</strong></li>
                {productsOptions}
            </>
        }
        {
            authors.length > 0 &&
            <>
                <li><strong>Autores</strong></li>
                {authorsOptions}
            </>
        }
        {
            brands.length > 0 &&
            <>
                <li><strong>Editoriales</strong></li>
                {brandsOptions}
            </>
        }
    </ul>
}

export default SiteSearchSuggetions