import axios from 'axios'

export const CONFIRM_ORDER_ADDRESS = 'CONFIRM_ORDER_ADDRESS'
export const MAKE_ORDER = 'MAKE_ORDER'

export const confirmAddress = ( client ) => ({
  type: CONFIRM_ORDER_ADDRESS,
  payload: axios.post('/account/order/confirm-address', { client },
    {
      params: {
        uuid: localStorage.getItem('entelequia-cart-uuid'),
        currency: localStorage.getItem('entelequia-currency')
      }
    })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})