import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import {loginWithGoogle} from '../actions/auth'
import { Col, Container, Row } from "react-bootstrap"
import { FaSpinner } from 'react-icons/fa'
import { useEffect } from 'react'
import history from '../history'


const LoginGoogle = () => { 

    const dispatch = useDispatch()
    // ?code=4%2F0AeanS0YUMkR4ELKJxeXf9eVw3thERYzZcj-BNS-D3zhDj7gUd4TjL2tuEwouH57Y36ud1g&scope=email+profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+openid&authuser=0&prompt=none

    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        const queryParamsString = window.location.search

        dispatch(loginWithGoogle(queryParamsString))
            .then((result) => {
                if(result.value.access_token != null){
                    history.push('/cuenta/')
                }
            })
            .catch((error) => {
                setErrorMessage('Error al intentar ingresar con Google')
                console.log('Error al intentar ingresar con Google', error)
            });
    }, [])

    return <Container className="inner-page newsletter-thanks" style={{paddingBottom: '80px', paddingTop: '100px'}}>
        <Row>
            <Col xs={12}>
            {
                errorMessage == null
                ?
                <>
                    <h3 className="text-center" style={{border: 'none'}}>Autentificando</h3>
                    <FaSpinner icon="spinner" className="button-spinner animate-spin" style={{fontSize: '50px', margin: '20px auto', display: 'block'}}/>
                </>
                :
                    <>
                        <h3 className="text-center" style={{border: 'none'}}>{errorMessage}</h3>
                        <p className="text-center"><a href='/login' className='btn btn-primary'>Volver a intentar</a></p>
                    </>

            }
               
            </Col>
        </Row>
    </Container>

}

export default LoginGoogle