import React from 'react'
import { useDispatch } from 'react-redux'
import { redirectToGoogleUrl } from '../actions/auth'


const GoogleLoginButton = () => {

    const dispatch = useDispatch()

    const handleOnGoogleLoginRedirect = () => {
        dispatch(redirectToGoogleUrl())
        .then((result) => {
            if(result.value.data.googleUrl != null){
                window.location.href = result.value.data.googleUrl
            }else{
                console.log('Error al intentar ingresar con Google')
            }
        })
    }

    return (
        <div style={{display: "none"}}>
            <button className='facebook-button' onClick={handleOnGoogleLoginRedirect}>
                <img src="images/icons/google.svg" alt="Google Inicio" />
            </button>
        </div>
    )

}

export default GoogleLoginButton