import { SHOW_CLIENT_PROFILE } from '../actions/client'
import { USER_LOGOUT } from '../actions/auth'

const INITIAL_STATE = {
    profile: null,
    isFetching: false,
    isSuccess: false,
    error: null,
  }

export const showClientProfile = (state = INITIAL_STATE, action) => {
    switch(action.type){
      case `${SHOW_CLIENT_PROFILE}_REJECTED`:
        return {
          ...state,
          fetched: false,
          error: action.payload,
          isSuccess: false,
        }
      case `${SHOW_CLIENT_PROFILE}_PENDING`:
        return {
          ...state,
          isFetching: true,
          error: null,
          isSuccess: false,
        }
      case `${SHOW_CLIENT_PROFILE}_FULFILLED`:
        return {
          ...state,
          isFetching: false,
          profile: action.payload.profile,
          isSuccess: true,
        }
      case `${SHOW_CLIENT_PROFILE}`:
        return {
          ...state,
          isFetching: action.payload.state,
          isSuccess: false,
        }
      case `${USER_LOGOUT}`:
        return {...INITIAL_STATE};
      default: 
        return state
    }
  }