import React from 'react'
import { Toast } from 'react-bootstrap'

const Pop = ({unSetPopup, message}) => {
  return (
    <Toast onClose={unSetPopup} delay={3000} autohide position='top-end'>
      <Toast.Header>
        <img
          src="holder.js/20x20?text=%20"
          className="rounded me-2"
          alt=""
        />
        <strong className="me-auto">Entelequia</strong>
        <small></small>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  )
}

export default Pop