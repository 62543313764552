import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, FormGroup, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Alert from '../components/alert'
import {registration} from '../actions/auth'

const Registration = () => { 
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [registrationData, setRegistrationData] = useState({
    name: '',
    surname: '',
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
  })
  const [registrationMessage, setRegistrationMessage] = useState(null)

  useEffect(() => {
    if(!location.state){
      history.push('/login')
    } else {
      let {email,password, newsletterRegistration} = location.state
      setRegistrationData({...registrationData, 'email': email, 'password': password, newsletterRegistration: newsletterRegistration})
    }
  },[])

  const handleInputChange = ( e  ) => {
    const { name, value } = e.target
    setRegistrationData({...registrationData, [name]: value})
  }
    
    const onSubmit = (evt) => {
        evt.preventDefault()
        dispatch(registration(registrationData))
          .then(() => {
            history.push({
              pathname: '/registracion-mail',
              state : {
                registrationMail : registrationData.email
              }
            })
          }).catch((error) => {
            setRegistrationMessage(error.response.data.message)
          })
      }
    
  return (
    <Container fluid className="inner-page desktop profile max-container registracion">
    <h3>Registro</h3>
    <Row className="ml-0 mr-0">
      <Col lg={12} className="profile-actions">
        <div className="green-box">
          <h4 className='text-left'>Ingresar datos de usuario</h4>
          {registrationMessage && 
              <div className="w-full lg:1/2 mb-4" style={{maxWidth: '550px'}}>
                <Alert
                  color="bg-transparent border-red-500 text-red-500"
                  borderLeft
                  raised>
                  {registrationMessage}
                </Alert>
              </div>
            }
          <Form onSubmit={onSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Form.Label for="name">Nombre *</Form.Label>
                  <Form.Control type="text" name="name" value={registrationData.name} onChange={e => handleInputChange(e)} required/>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Form.Label for="surname">Apellido *</Form.Label>
                  <Form.Control type="text" name="surname" value={registrationData.surname}  onChange={e => handleInputChange(e)} required/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Form.Label for="username">Nombre visible *</Form.Label>
                  <Form.Control type="text" name="username" value={registrationData.username}  onChange={e => handleInputChange(e)} required />
                </FormGroup>
                <p>*Así será como se mostrará tu nombre en la sección de tu cuenta y en las valoraciones</p>
              </Col>
              <Col>
                <FormGroup>
                  <Form.Label for="email">Dirección de correo electrónico *</Form.Label>
                  <Form.Control type="email" name="email" value={registrationData.email} onChange={e => handleInputChange(e)} required/>
                </FormGroup>
              </Col>
            </Row>
            <h4 className="title-two text-left">Contraseña</h4>
            <Row>
              <Col>
                <FormGroup>
                  <Form.Label for="password">Contraseña</Form.Label>
                  <Form.Control type="password" name="password"  value={registrationData.password} onChange={e => handleInputChange(e)}/>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Form.Label for="password_confirmation">Confirmar contraseña</Form.Label>
                  <Form.Control type="password" name="password_confirmation" value={registrationData.password_confirmation} onChange={e => handleInputChange(e)}/>
                </FormGroup>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              REGISTRARSE
            </Button>
          </Form>
        </div>
      </Col>
    </Row>
  </Container>
  )
}

export default Registration