import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { calculateShipmentCost, changeCartItemQuantity, removeCartItem, showCart, validateCoupon } from '../actions/cart'
import { Container, Row, Col, Button } from 'react-bootstrap'
import InputSpinner from '../components/input-spinner'
import { usePopup } from '../context/popup-context'
import CountrySelect from '../components/country-select'

const Cart = () => {
  const {cart} = useSelector(state => state.showCart)
  const [couponCode, setCouponCode] = useState('')
  const [couponValidationMessage , setCouponValidationMessage] = useState('')
  const [country, setCountry] = useState('Argentina')
  const [countryCode, setCountryCode] = useState('AR')
  const [city, setCity] = useState('')
  const [zipCode, setZipCode] = useState('')
  const { setPopup } = usePopup()
  const dispatch = useDispatch()
  const [calculatingShipment, setCalculatingShipment] = useState(false)
  const [errorCalcultingShipment, setErrorCalculatingShipment] = useState(false)

//   const [offices, setOffices] = useState([
//     // { "cp": "1663", "id": 1219, "end": "23:00", "cuit": null, "dpto": null, "mail": null, "piso": null, "calle": "(Suc.Andreani ) - Serrano", "altura": "1695", "estado": "ACTIVA", "celular": null, "contacto": "Andreani", "telefono": null, "localidad": "SAN MIGUEL", "provincia": "BUENOS AIRES", "other_info": null, "descripcion": "Sucursal para Retiros", "razonSocial": "Sucursal  Andreani", "full_address": "(Suc.Andreani ) - Serrano 1695   -  ", "codigo_sucursal": "San Miguel", "horario_atencion": "Lunes a Viernes de 09:00 a 18:00 - S\u0087bado de 09:00 a 13:00" },
//     // { "cp": "1650", "id": 2020, "end": "23:00", "cuit": null, "dpto": null, "mail": null, "piso": null, "calle": "( Suc.Andreani ) - Carrillo", "altura": "2176", "estado": "ACTIVA", "celular": null, "contacto": "Andreani", "telefono": null, "localidad": "GENERAL SAN MARTIN", "provincia": "BUENOS AIRES", "other_info": null, "descripcion": "Sucursal para Retiros", "razonSocial": "Sucursal  Andreani", "full_address": "( Suc.Andreani ) - Carrillo 2176   -  ", "codigo_sucursal": "San Martin", "horario_atencion": "Lunes a Viernes de 08:00 a 19:00 - S\u0087bado de 09:00 a 13:00" }
//   ])
//   const [officePrice, setOfficePrice] = useState(null)
  
  useEffect(() => {
    dispatch(showCart(true))
  },[])

  useEffect(() => {
    if(cart){
      setCountry(cart.shipmentCountry?? 'Argentina')
      setCountryCode(cart.shipmentCountryCode?? 'AR')
      setCity(cart.shipmentCity?? '')
      setZipCode(cart.shipmentZipCode?? '')
      setCouponCode(cart.couponCode?? '')
    }
  },[cart])

  const onRemoveItem = (index) => {  
    let cartItem = cart.cartItems[index]
    dispatch(removeCartItem(cartItem.id))
      .then(() => {
        setPopup('El Producto ha sido removido del carrito correctamente!')
        dispatch(showCart(true))
      })
  }

  const onChangeQuantity = (cartItemId, quantity) => {
    dispatch(changeCartItemQuantity(cartItemId, quantity))
      .then(response => {
        dispatch(showCart(true))
          .then(() => setPopup('Se ha modificado la cantidad del producto correctamente!') )
      }).catch(error => {
        let msg = 'Error al modificar cantidad del producto. Si el problema persiste, comuniquese con nosotros.'
        if(error.response.status == 422){
          msg = error.response.data.errorMessage
        }
        setPopup(msg)
      })
  }

  const onApplyCoupon = () => {
    /*if(couponCode.length == 0){
      alert('Ingrege cupón')
    } else {*/
      dispatch(validateCoupon(couponCode))
        .then(response => {
          setPopup('Cupón aplicado correctamente!')
          setCouponValidationMessage("")
          dispatch(showCart(true))
        }).catch(error => {
          setCouponValidationMessage(error.response.data.errorMessage)
        })
    //}
  }

  const onUpdateCart = () => {
    dispatch(showCart(true))
  }

  const onShipCalculation = (evt) => {
    evt.preventDefault()
    setCalculatingShipment(true)
    setErrorCalculatingShipment(false)
    dispatch(calculateShipmentCost(zipCode, country, countryCode, city))
      .then((data) => {
        dispatch(showCart(true))
        setCalculatingShipment(false)

        // try {
        //   console.log('data.value.possibleShippingOffices?.offices', data.value.possibleShippingOffices?.offices)
        //   if(data.value.possibleShippingOffices && data.value.possibleShippingOffices.offices.length > 0 && data.value.possibleShippingOffices.shipment_cost > 0){
        //     setOffices(data.value.possibleShippingOffices.offices)
        //     setOfficePrice(data.value.possibleShippingOffices.shipment_cost)
        //   }
          
        // } catch (error) {
        //   console.log('error possibleShippingOffices', error)
        // }

      }).catch( () => {
        setCalculatingShipment(false)
        setErrorCalculatingShipment(true)
      })

  }

  const selectCountry = (option) => {
    setCountry(option.value)
    setCountryCode(option.code)
    setCity('')
    setZipCode('')
  }

  return (
    <Container fluid className="inner-page cart max-container">
      <div className="breadcrumb pl-0">
        <Link to="/">Inicio</Link> / CARRITO DE COMPRAS
      </div>
      <h3>Carrito de compras</h3>
      <Row>
        { !cart || Object.keys(cart).length == 0 || cart.cartItems?.length == 0 ?
              <Col><h5 className="text-center pt-5 pb-5">No hay productos en el carrito</h5></Col>
            : 
              <>
                <Col xs={12} md={8} className="detail">
                  <div style={{maxWidth: '100%', overflowX: 'auto'}}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Producto</th>
                          <th>Precio</th>
                          <th>Cantidad</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          cart.cartItems.map((cartItem,i) =>
                            <tr key={i}>
                              <td className="delete"><button onClick={() => onRemoveItem(i)}>X</button></td>
                              <td className="description"><a href={`/producto/${cartItem.product?.slug}`} target='_blanck' style={{color: '#000'}}>{cartItem.product.title}</a></td>
                              <td className="price">{cartItem.product.price.currency} {cartItem.product.price.amount}</td>
                              <td><InputSpinner quantity={cartItem.quantity} onClickHandler={(quantity)=> onChangeQuantity(cartItem.id,quantity)}/></td>
                              <td className="subtotal">{cartItem.totalPrice.currency} {cartItem.totalPrice.amount}</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                  <Row className="buttons-holder">
                    <Col xs={12} className="text-center text-xl-left">
                      <input 
                        type="text" 
                        className="input-coupon mb-xs-2 mr-1 ml-1 col-12 col-md-6" 
                        value={couponCode} 
                        onChange={(e) => {setCouponCode(e.target.value)}} 
                        placeholder="Ingrese código de descuento..." 
                        title="Si tenés un cupón o una giftcard ingresala aquí"/>
                      <Button className="apply-coupon btn-primary-green btn btn-primary mt-sm-0 mt-2" onClick={() => onApplyCoupon()}>Aplicar</Button>
                      <p className="mt-2 pl-2" style={{color: '#ff010d'}}>{couponValidationMessage}</p>
                    </Col>
                    <Col xs={12} xl={5} className="text-center text-xl-right mt-2 mt-md-0 d-none">
                      <Button className="update-cart btn-primary-green" onClick={() => onUpdateCart()}>Actualizar carrito</Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={4} className="summary mt-5 mt-md-0">
                  <div className="box">
                    <h4 className="text-center text-md-left">Total del carrito</h4>
                    <p className="ml-auto mr-auto ml-md-0 mr-md-0 text-center text-md-left">Introducí tu dirección para ver las opciones de envío.</p>
                    <form onSubmit={onShipCalculation}>
                      <CountrySelect
                        name="country"
                        value={country}
                        onCountrySelect={(option) => selectCountry(option)}
                        isRequired={true}/>
                      {
                        <>
                          { country !== 'Argentina' &&
                            <input 
                              name="city"
                              value={city}
                              onChange={(e)=> setCity(e.target.value)} 
                              className="form-control zipcode mb-4 ml-md-0 mr-md-0" 
                              placeholder="Ciudad"
                              required/>
                          }
                          <input 
                            name="zipCode"
                            value={zipCode}
                            onChange={(e)=> setZipCode(e.target.value)} 
                            className="form-control zipcode ml-md-0 mr-md-0" 
                            placeholder="Código postal"
                            required={ country === 'Argentina'? 'required' : ''  }
                            />
                          
                          { calculatingShipment === true ?
                            <div style={{marginBottom: '20px'}}>Calculando envío...</div>
                          :
                            errorCalcultingShipment === true ?
                              <>
                              <button className="ship-calculator" style={{marginBottom: '10px'}}>Calcular envío</button>
                                <div style={{marginBottom: '50px', marginLeft: '6px', color: 'red'}}>Error al calcular costo de envío</div>
                              </>
                            :
                              <button className="ship-calculator">Calcular envío</button>
                          }
                          </>
                      }
                    </form>

					<div className='envios-info'>
						{
							cart?.isFreeShip ?
							(
								<h5>Envío gratis</h5>
							) : (
								<>

									{
										cart?.possibleShippingOffices?.offices.length > 0 && 
										<>
											<h5>Envío a domicilio <label className="price">$ {cart?.shipmentAmount.amount}</label></h5>
											{cart?.possibleShippingOffices?.offices.length > 0 && 
												<div className='sucursales'>
													<h5>Punto de retiro <label className="price">$ {cart?.possibleShippingOffices?.shipment_cost}</label></h5>
													
													<div className='sucursales_list'>
													{cart?.possibleShippingOffices?.offices.map((office, index) => {
														return (
														<div key={index} className='sucursal_item'>
															{office.full_address} <strong>{office.localidad}, {office.provincia}</strong> 
														</div>
														)
													})}
													</div>
						
												</div>
											}

										</>
									}

								
								</>
							)
						}
					</div>
                      

                    <div className="subtotal">
                      <p>Descuento:</p>
                      <p className="price">{cart?.couponAmount.currency} {cart?.couponAmount.amount + cart?.giftCardAmount.amount+ cart?.discountAmount.amount}</p>
                    </div>

                    
                    <div className="subtotal">
                      <p>Envío:</p>
                      {
                        cart?.isFreeShip ?
                          <p className="price">
                            <span style={{color: '#16b54c', fontWeight: 'bold'}}>Envío gratis</span>
                          </p>
                        :
                          <p className="price">
                            <span>{cart?.shipmentAmount.currency} {cart?.shipmentAmount.amount}</span>
                          </p>
                      }
                    </div>


                    <div className="total">
                      <p>Total:</p>
                      <p className="price">{cart.totalPrice.currency} {cart.totalPrice.amount}</p>
                    </div>
                    <Link to="/cuenta/checkout" className="finish-buy">Realizar compra</Link>
                  </div>
                </Col>
              </>
        }
      </Row>
    </Container>
  )
}

export default Cart