export const countryOptions = [
    { value: "", code: "", label: "Seleccione su país"},
    { value: "Afganistán", code: "AF", label: "Afganistán"},
    { value: "Albania", code: "AL", label: "Albania"},
    { value: "Alemania", code: "DE", label: "Alemania"},
    { value: "Andorra", code: "AD", label: "Andorra"},
    { value: "Angola", code: "AO", label: "Angola"},
    { value: "Antigua y Barbuda", code: "AG", label: "Antigua y Barbuda"},
    { value: "Arabia Saudí", code: "SA", label: "Arabia Saudí"},
    { value: "Argelia", code: "DZ", label: "Argelia"},
    { value: "Argentina", code: "AR", label: "Argentina"},
    { value: "Armenia", code: "AM", label: "Armenia"},
    { value: "Australia", code: "AU", label: "Australia"},
    { value: "Austria", code: "AT", label: "Austria"},
    { value: "Azerbaiyán", code: "AZ", label: "Azerbaiyán"},
    { value: "Bahamas", code: "BS", label: "Bahamas"},
    { value: "Bangladesh", code: "BD", label: "Bangladesh"},
    { value: "Barbados", code: "BB", label: "Barbados"},
    { value: "Barein", code: "BH", label: "Barein"},
    { value: "Bélgica", code: "BE", label: "Bélgica"},
    { value: "Belice", code: "BZ", label: "Belice"},
    { value: "Benín", code: "BJ", label: "Benín"},
    { value: "Bielorrusia", code: "BY", label: "Bielorrusia"},
    { value: "Birmania", code: "MM", label: "Birmania"},
    { value: "Bolivia", code: "BO", label: "Bolivia"},
    { value: "Bosnia y Herzegovina", code: "BA", label: "Bosnia y Herzegovina"},
    { value: "Botsuana", code: "BW", label: "Botsuana"},
    { value: "Brasil", code: "BR", label: "Brasil"},
    { value: "Brunei", code: "BN", label: "Brunei"},
    { value: "Bulgaria", code: "BG", label: "Bulgaria"},
    { value: "Burkina Faso", code: "BF", label: "Burkina Faso"},
    { value: "Burundi", code: "BI", label: "Burundi"},
    { value: "Bután", code: "BTN", label: "Bután"},
    { value: "Cabo Verde", code: "CV", label: "Cabo Verde"},
    { value: "Camboya", code: "KH", label: "Camboya"},
    { value: "Camerún", code: "CM", label: "Camerún"},
    { value: "Canadá", code: "CA", label: "Canadá"},
    { value: "Chad", code: "TD", label: "Chad"},
    { value: "Chile", code: "CL", label: "Chile"},
    { value: "China", code: "CN", label: "China"},
    { value: "Chipre", code: "CY", label: "Chipre"},
    { value: "Colombia", code: "CO", label: "Colombia"},
    { value: "Comoras", code: "COM", label: "Comoras"},
    { value: "Congo", code: "CG", label: "Congo"},
    { value: "Corea del Sur", code: "KR", label: "Corea del Sur"},
    { value: "Corea del Norte", code: "KP", label: "Corea del Norte"},
    { value: "Costa del Marfíl", code: "CI", label: "Costa del Marfíl"},
    { value: "Costa Rica", code: "CR", label: "Costa Rica"},
    { value: "Croacia", code: "HR", label: "Croacia"},
    { value: "Cuba", code: "CU", label: "Cuba"},
    { value: "Dinamarca", code: "DK", label: "Dinamarca"},
    { value: "Dominica", code: "DM", label: "Dominica"},
    { value: "Ecuador", code: "EC", label: "Ecuador"},
    { value: "Egipto", code: "EG", label: "Egipto"},
    { value: "El Salvador", code: "SV", label: "El Salvador"},
    { value: "Emiratos Arabes Unidos", code: "AE", label: "Emiratos Arabes Unidos"},
    { value: "Eritrea", code: "ER", label: "Eritrea"},
    { value: "Eslovaquia", code: "SK", label: "Eslovaquia"},
    { value: "Eslovenia", code: "SI", label: "Eslovenia"},
    { value: "España", code: "ES", label: "España"},
    { value: "Estados Unidos", code: "US", label: "Estados Unidos"},
    { value: "Estonia", code: "EE", label: "Estonia"},
    { value: "Etiopía", code: "ET", label: "Etiopía"},
    { value: "Filipinas", code: "PH", label: "Filipinas"},
    { value: "Finlandia", code: "FI", label: "Finlandia"},
    { value: "Fiyi", code: "FJ", label: "Fiyi"},
    { value: "Francia", code: "FR", label: "Francia"},
    { value: "Gabón", code: "GA", label: "Gabón"},
    { value: "Gambia", code: "GM", label: "Gambia"},
    { value: "Georgia", code: "GE", label: "Georgia"},
    { value: "Ghana", code: "GH", label: "Ghana"},
    { value: "Granada", code: "GD", label: "Granada"},
    { value: "Grecia", code: "GR", label: "Grecia"},
    { value: "Guatemala", code: "GT", label: "Guatemala"},
    { value: "Guayana", code: "GY", label: "Guayana"},
    { value: "Guinea", code: "GN", label: "Guinea"},
    { value: "Guinea Ecuatorial", code: "GQ", label: "Guinea Ecuatorial"},
    { value: "Guinea-Bissau", code: "GW", label: "Guinea-Bissau"},
    { value: "Haití", code: "HT", label: "Haití"},
    { value: "Holanda", code: "NL", label: "Holanda"},
    { value: "Honduras", code: "HN", label: "Honduras"},
    { value: "Hong Kong", code: "HK", label: "Hong Kong"},
    { value: "Hungría", code: "HU", label: "Hungría"},
    { value: "India", code: "IN", label: "India"},
    { value: "Indonesia", code: "data-code", label: "Indonesia"},
    { value: "Irak", code: "IQ", label: "Irak"},
    { value: "Irán", code: "IR", label: "Irán"},
    { value: "Irlanda", code: "IE", label: "Irlanda"},
    { value: "Islandia", code: "IS", label: "Islandia"},
    { value: "Israel", code: "IL", label: "Israel"},
    { value: "Italia", code: "IT", label: "Italia"},
    { value: "Jamaica", code: "JM", label: "Jamaica"},
    { value: "Japón", code: "JP", label: "Japón"},
    { value: "Jordania", code: "JO", label: "Jordania"},
    { value: "Kazajistán", code: "KZ", label: "Kazajistán"},
    { value: "Kenia", code: "KE", label: "Kenia"},
    { value: "Kirguizistán", code: "KG", label: "Kirguizistán"},
    { value: "Kiribati", code: "KI", label: "Kiribati"},
    { value: "Kuwait", code: "KW", label: "Kuwait"},
    { value: "Laos", code: "LA", label: "Laos"},
    { value: "Lesoto", code: "LS", label: "Lesoto"},
    { value: "Letonia", code: "LV", label: "Letonia"},
    { value: "Líbano", code: "LB", label: "Líbano"},
    { value: "Liberia", code: "LR", label: "Liberia"},
    { value: "Libia", code: "LY", label: "Libia"},
    { value: "Liechtenstein", code: "LI", label: "Liechtenstein"},
    { value: "Lituania", code: "LT", label: "Lituania"},
    { value: "Luxemburgo", code: "LU", label: "Luxemburgo"},
    { value: "Madagascar", code: "MKD", label: "Macedonia"},
    { value: "Madagascar", code: "MG", label: "Madagascar"},
    { value: "Malasia", code: "MY", label: "Malasia"},
    { value: "Malawi", code: "MW", label: "Malawi"},
    { value: "Maldivas", code: "MV", label: "Maldivas"},
    { value: "Malí", code: "ML", label: "Malí"},
    { value: "Malta", code: "MT", label: "Malta"},
    { value: "Islas Marshall", code: "MHL", label: "Islas Marshall"},
    { value: "Marruecos", code: "MA", label: "Marruecos"},
    { value: "Martinica", code: "MQ", label: "Martinica"},
    { value: "Mauricio", code: "MU", label: "Mauricio"},
    { value: "Mauritania", code: "MR", label: "Mauritania"},
    { value: "México", code: "MX", label: "México"},
    { value: "Micronesia", code: "FM", label: "Micronesia"},
    { value: "Moldavia", code: "MD", label: "Moldavia"},
    { value: "Mónaco", code: "MC", label: "Mónaco"},
    { value: "Mongolia", code: "MN", label: "Mongolia"},
    { value: "Montenegro", code: "MNE", label: "Montenegro"},
    { value: "Mozambique", code: "MZ", label: "Mozambique"},
    { value: "Namibia", code: "NA", label: "Namibia"},
    { value: "Nauru", code: "NR", label: "Nauru"},
    { value: "Nepal", code: "NP", label: "Nepal"},
    { value: "Nicaragua", code: "NI", label: "Nicaragua"},
    { value: "Níger", code: "NE", label: "Níger"},
    { value: "Nigeria", code: "NG", label: "Nigeria"},
    { value: "Noruega", code: "NO", label: "Noruega"},
    { value: "Nueva Zelanda", code: "NZ", label: "Nueva Zelanda"},
    { value: "Omán", code: "OM", label: "Omán"},
    { value: "Paises Bajos", code: "NLD", label: "Paises Bajos"},
    { value: "Palaos", code: "PLW", label: "Palaos"},
    { value: "Paquistán", code: "PK", label: "Paquistán"},
    { value: "Panamá", code: "PA", label: "Panamá"},
    { value: "Papua Nueva Guinea", code: "PG", label: "Papua Nueva Guinea"},
    { value: "Paraguay", code: "PY", label: "Paraguay"},
    { value: "Perú", code: "PE", label: "Perú"},
    { value: "Polonia", code: "PL", label: "Polonia"},
    { value: "Portugal", code: "PT", label: "Portugal"},
    { value: "Puerto Rico", code: "PR", label: "Puerto Rico"},
    { value: "Qatar", code: "QA", label: "Qatar"},
    { value: "Reino Unido", code: "GB", label: "Reino Unido"},
    { value: "República Centroafricana", code: "CF", label: "República Centroafricana"},
    { value: "República Checa", code: "CZ", label: "República Checa"},
    { value: "República Democrática del Congo", code: "CD", label: "República Democrática del Congo"},
    { value: "República Dominicana", code: "DO", label: "República Dominicana"},
    { value: "Ruanda", code: "RW", label: "Ruanda"},
    { value: "Rumania", code: "RO", label: "Rumania"},
    { value: "Rusia", code: "RU", label: "Rusia"},
    { value: "Samoa", code: "WS", label: "Samoa"},
    { value: "Samoa occidental", code: "AS", label: "Samoa occidental"},
    { value: "San Marino", code: "SM", label: "San Marino"},
    { value: "San Vicente e Islas Granadinas", code: "VC", label: "San Vicente e Islas Granadinas"},
    { value: "Santa Lucía", code: "LC", label: "Santa Lucía"},
    { value: "Santo Tomé y Príncipe", code: "ST", label: "Santo Tomé y Príncipe"},
    { value: "Senegal", code: "SN", label: "Senegal"},
    { value: "Serbia", code: "YU", label: "Serbia"},
    { value: "Seychelles", code: "SC", label: "Seychelles"},
    { value: "Sierra Leona", code: "SL", label: "Sierra Leona"},
    { value: "Singapur", code: "SG", label: "Singapur"},
    { value: "Siria", code: "SY", label: "Siria"},
    { value: "Somalia", code: "SO", label: "Somalia"},
    { value: "Sri Lanka", code: "LK", label: "Sri Lanka"},
    { value: "Suazilandia", code: "SZ", label: "Suazilandia"},
    { value: "Sudáfrica", code: "ZA", label: "Sudáfrica"},
    { value: "Sudán", code: "SD", label: "Sudán del sur"},
    { value: "Suecia", code: "SE", label: "Suecia"},
    { value: "Suiza", code: "CH", label: "Suiza"},
    { value: "Surinam", code: "SR", label: "Surinam"},
    { value: "Tailandia", code: "TH", label: "Tailandia"},
    { value: "Taiwán", code: "TWN", label: "Taiwán"},
    { value: "Tanzania", code: "TZ", label: "Tanzania"},
    { value: "Tayikistán", code: "TJ", label: "Tayikistán"},
    { value: "Timor Oriental", code: "TP", label: "Timor Oriental"},
    { value: "Togo", code: "TG", label: "Togo"},
    { value: "Tonga", code: "TO", label: "Tonga"},
    { value: "Trinidad y Tobago", code: "TT", label: "Trinidad y Tobago"},
    { value: "Túnez", code: "TN", label: "Túnez"},
    { value: "Turkmenistán", code: "TM", label: "Turkmenistán"},
    { value: "Turquía", code: "TR", label: "Turquía"},
    { value: "Tuvalu", code: "TV", label: "Tuvalu"},
    { value: "Ucrania", code: "UA", label: "Ucrania"},
    { value: "Uganda", code: "UG", label: "Uganda"},
    { value: "Uruguay", code: "UY", label: "Uruguay"},
    { value: "Uzbekistán", code: "UZ", label: "Uzbekistán"},
    { value: "Vaticano", code: "VAT", label: "Vaticano"},
    { value: "Vanuatu", code: "VU", label: "Vanuatu"},
    { value: "Venezuela", code: "VE", label: "Venezuela"},
    { value: "Vietnam", code: "VN", label: "Vietnam"},
    { value: "Yemen", code: "YE", label: "Yemen"},
    { value: "Yibuti", code: "DJI", label: "Yibuti"},
    { value: "Zambia", code: "ZM", label: "Zambia"},
    { value: "Zimbabue", code: "ZW", label: "Zimbabue"},
]