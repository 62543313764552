import axios from 'axios'

export const GET_AUTHOR = 'GET_AUTHOR'

export const getAuthor = (slug) => ({
    type: GET_AUTHOR,
    payload: axios.get('/authors',{
        params: {
          uuid: localStorage.getItem('entelequia-cart-uuid'),
          currency: localStorage.getItem('entelequia-currency'),
          slug
        }
      })
        .then(response => response.data)
        .catch(error => Promise.reject(error))
})