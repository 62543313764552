import React,{useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { findTreeCategories } from '../actions/category'
import { Container, Row, Col, Nav, Navbar, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { showCart } from '../actions/cart'
import CategoryDropdown2 from '../components/category-dropdown2'
import CurrencyDropdown from '../components/currency-dropdown'
import SiteSearch from '../components/site-search'
import NotificationDropdown from '../components/notification-dropdown'
import { PUBLIC_URL, REACT_APP_BASENAME } from '../helpers/domain'


const Header = () => {
  const [categories, setCategories] = useState([])
  const [cartItems,setCartItems] = useState(0)
  const [cartTotal, setCartTotal] = useState({ currency: localStorage.getItem('entelequia-currency')?? 'ARS', amount: '0.0'})
  const {cart} = useSelector(state => state.showCart)
  const dispatch = useDispatch()
  let publicUrl = PUBLIC_URL

  useEffect(() => {
    dispatch(findTreeCategories())
      .then(response => {
        let categoriesTree = response.value
        setCategories(categoriesTree)
      })
    dispatch(showCart())
  },[])


  useEffect(() => {
    if(cart != null && cart.cartItems?.length > 0){
      // setCartItems(cart.cartItems.length)
      setCartItems(cart.cartItems.reduce((acc, item) => acc + item.quantity, 0))
      setCartTotal(cart.productPrice)
    } else {
      setCartTotal({ currency: localStorage.getItem('entelequia-currency')?? 'ARS', amount: '0.0'})
      setCartItems(0)
    }
  }, [cart])


  return (
    <Container fluid >
      <Row className="topbar">
        <Col xs={12} md={8}>
          <p className="text-center text-md-left">ENTELEQUIA CENTRO: URUGUAY 341 - (011) 4372-7282 | ENTELEQUIA BELGRANO: JURAMENTO 2584 - (011) 4788-4521<br/>Distribución a traves de Bam Comics SRL</p>
        </Col>
        <Col xs={4} className="social d-none d-md-block pl-0">
          <Link to="/contacto" className="contact">Contacto</Link>
          <ListGroup horizontal>
            <ListGroup.Item>
              <a href="https://www.instagram.com/entelequiagram/" target="_blank" rel="noreferrer"><img src={`${publicUrl}/images/icons/instagram.png`} alt="Instagram"/></a>
            </ListGroup.Item>
            <ListGroup.Item>
              <a href="https://www.facebook.com/entelequia.comicbookstore/" target="_blank" rel="noreferrer">
                <img src={`${publicUrl}/images/icons/facebook.png`} alt="Facebook"/>
              </a>    
            </ListGroup.Item>
            <ListGroup.Item>
              <a href="https://api.whatsapp.com/send?phone=541124043966&text=Hola%20*Entelequia%20Comic%20Book%20Store*" target="_blank" rel="noreferrer">
                <img src={`${publicUrl}/images/icons/wap.png`} alt="What's app"/>
              </a>    
            </ListGroup.Item>  
          </ListGroup>
        </Col>
      </Row>
    
      <Navbar expand="md">
        <Navbar.Toggle/>
        <Navbar.Brand href={`${REACT_APP_BASENAME}`}>            
          <img src={`${publicUrl}/images/logo.png`} alt="Entelequia" className="img-fluid logo-header"/>
        </Navbar.Brand>
        <Navbar.Collapse id="navbarScroll" className="justify-content-between pt-4">
          <Nav className="w-100">
            <SiteSearch/>
          </Nav>
          <Nav className="actions align-items-center">
            <div className="icons d-flex align-items-center pt-2 pt-md-0">
              <CurrencyDropdown/>
              <Link to="/cuenta/" className="flex-row"><img src={`${publicUrl}/images/icons/user.png`} alt=""/></Link>
              <NotificationDropdown/>
              <Link to="/cuenta/favoritos" className="flex-row"><img src={`${publicUrl}/images/icons/heart.png`} alt=""/></Link>
            </div>
            <Nav.Item>
              <div  className="cart">
                <Link to="/cart">
                  <img src={`${publicUrl}/images/icons/cart.png`} alt=""/>
                  <p className="cart-bag" >
                    <span className="cart-amount">{cartTotal.currency} {cartTotal.amount}</span><br/>
                    <span className="item-count">{cartItems} item</span>
                  </p>
                </Link>
              </div>
            </Nav.Item>
            <Nav.Item>
              <div className="social d-md-none text-center">
                <Link to="/contacto" className="contact">Contacto</Link>
                <Link to="/nosotros" className="contact">Nosotros</Link>
                <Link to="/como-comprar" className="contact">Como comprar</Link>
                <ListGroup horizontal className="justify-content-center">
                  <ListGroup.Item>
                    <a href="instagram.com" target="_blank"><img src={`${publicUrl}/images/icons/instagram.png`} alt="Instagram"/></a>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <a href="facebook.com" target="_blank">
                      <img src={`${publicUrl}/images/icons/facebook.png`} alt="Facebook"/>
                    </a>    
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <a href="##" target="_blank">
                      <img src={`${publicUrl}/images/icons/wap.png`} alt="What's app"/>
                    </a>    
                  </ListGroup.Item>  
                </ListGroup>
              </div>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Row className="navigation-bar">
        <Col xs={12} md={3}>
          <CategoryDropdown2 categories={categories}/>
        </Col>
        <Col xs={12} md={9}>
          <ListGroup horizontal className="justify-content-md-end">
            <ListGroup.Item className="d-none d-md-block"  >
              <Link to="/como-comprar">Cómo comprar</Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link to="/ofertas">Ofertas</Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link to='/novedades'>Novedades</Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link to='/preventa'>Preventa</Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link to='/giftcards' translate="no">Giftcards</Link>
            </ListGroup.Item>
            <ListGroup.Item className="d-none d-md-block">
              <Link to='/nosotros'>Nosotros</Link>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

    </Container>
  )
}
  
export default Header