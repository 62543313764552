import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

const RecoverPasswordMessage = () => 
    <Container fluid className="inner-page connect max-container">
      <Row className="ml-0 mr-0 mt-5 mb-4">
        <Col lg={{ span: 8, offset: 2}} className="login">
            <div className="green-box">
                <h4 className='text-center'>Restaurar la clave de acceso</h4>
                <h5 className='mt-5 mb-5'>Te hemos enviado un mail con las instrucciones para que puedas recuperar tu clave de acceso</h5>
                <div className="btn-wrap text-center">
                  <Link 
                    to="/" 
                    className="btn btn-primary-green"
                    style={{width: '180px'}}>Ir al home</Link>
                </div>
            </div>        
        </Col>
      </Row>
    </Container>
    

export default RecoverPasswordMessage