import axios from 'axios'
import { addAxiosToken } from '../helpers/axios'

export const USER_LOGIN = 'USER_LOGIN'
export const USER_REGISTRATION = 'USER_REGISTRATION'
export const LOGIN_WITH_FACEBOOK = 'LOGIN_WITH_FACEBOOK'

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD'
export const RECOVER_PASSWORD_VALIDATION = 'RECOVER_PASSWORD_VALIDATION'
export const RENEW_PASSWORD = 'RENEW_PASSWORD'

export const USER_LOGOUT = 'USER_LOGOUT'

export const REDIRECT_TO_GOOGLE = 'REDIRECT_TO_GOOGLE'
export const LOGIN_WITH_GOOGLE = 'LOGIN_WITH_GOOGLE'

export const login = (email, password) => ({
    type: USER_LOGIN,
    payload: axios.post('/login', { email, password })
      .then(response => {
        if(response.status === 200 ){
          let {access_token} = response.data
          addAxiosToken(access_token)
          localStorage.setItem('entelequia-access-token-v2', access_token)
        } 
        return response.data
      })
      .catch(error => Promise.reject(error))
    })
  
export const loginWithFacebook = access_token => ({
    type: LOGIN_WITH_FACEBOOK,
    payload: axios.post(`/login/facebook`,{access_token})
    .then(response => {
      if(response.status === 200 ){
        let {access_token} = response.data
        addAxiosToken(access_token)
        localStorage.setItem('entelequia-access-token-v2', access_token)
      } 
      return response.data
    })
      .catch(error => Promise.reject(error))
  })

export const registration = (registration) => ({
    type: USER_REGISTRATION,
    payload: axios.post('/registration', {...registration})
      .then(response => (response))
      .catch(error => Promise.reject(error))
  })
  
  export const recoverPassword = email => ({
    type: RECOVER_PASSWORD,
    payload: axios.post('/recover-password', {email})
      .then(response => (response))
      .catch(error => Promise.reject(error))
  })
  
  export const recoverPasswordValidation = (email, token) => ({
    type: RENEW_PASSWORD,
    payload: axios.post('/recover-password-validation', {email, token})
    .then(response => {
      if(response.status === 200 ){
        let {access_token} = response.data
        addAxiosToken(access_token)
        localStorage.setItem('entelequia-access-token-v2', access_token)
      } 
      return response.data
    })
      .catch(error => Promise.reject(error))
  })
  
  export const renewPassword = (password, password_confirmation) => ({
    type: RENEW_PASSWORD,
    payload: axios.post('/account/renew-password', {password, password_confirmation})
      .then(response => ( response ))
      .catch(error => Promise.reject(error))
  })



  export const redirectToGoogleUrl = () => ({
    type: REDIRECT_TO_GOOGLE,
    payload: axios.get('/login/google')
      .then(response => (response))
      .catch(error => Promise.reject(error))
  })

  export const loginWithGoogle = (queryParams) => ({
    type: LOGIN_WITH_GOOGLE,
    payload: axios.post('/login/google' + queryParams)
      .then(response => {
        if(response.status === 200 ){
          let {access_token} = response.data
          addAxiosToken(access_token)
          localStorage.setItem('entelequia-access-token-v2', access_token)
        } 
        return response.data
      })
      .catch(error => Promise.reject(error))
  })