import React from 'react'
import { countryOptions } from './country-select-data'

const CountrySelect = ({name, value, onCountrySelect, isRequired = false}) => {
  
  const onChage = (evt) => {
    let selectedValue = evt.target.value
    let selectedCountry = countryOptions.find(element => element.value === selectedValue )
    onCountrySelect(selectedCountry)
  }

  const setSelectValue = (value) => {
    let selectedCountry = countryOptions.find(element => element.value === value)
    return selectedCountry?.value
  }
  
  return (
    <select
      name={name}
      className="country-autoselect"
      placeholder="País"
      onChange={evt => onChage(evt)}
      value={setSelectValue(value)} 
      required={isRequired}
      >
        { countryOptions.map((e,i) => 
          <option value={e.value} key={i}>{e.label}</option>
        )}
      </select>
  )
}

export default CountrySelect