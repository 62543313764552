import { Container, Row, Col } from "react-bootstrap"
import {Link} from 'react-router-dom'

const HowToBuy = () => {

  return (
    <Container fluid className="inner-page how-to-buy">
      <div className="breadcrumb pl-0"><Link to="/">Inicio</Link> / COMO COMPRAR</div>
      <div className="title">
        <h3>Comprar en nuestra tienda online es <strong>fácil y rápido</strong></h3>
        <p>Solo tenés que seguir estos pasos</p>
      </div>
      <Row className="steps">
        <Col xs={12} lg={8} className="item">
          <div className="number">
            <img src="images/icons/cart-rounded.png" className="icon" alt="Agregar"/>
            <p>01.</p>
          </div>
          <h4>Seleccioná el producto que deseás comprar</h4>
          <p>Una vez que hayas visto todas las categorías, hacé clic en el boton
          "Agregar al carrito" del producto que desees comprar. Aparecerá una
          ventana en la parte superior derecha de tu pantalla indicando que se
          agregó un producto nuevo al carrito de compras. Hacé click en este.</p>
          <p>En el carrito de compras podrás modificar el número de unidades del
          producto que quieres comprar y revisar el subtotal de tu compra.</p>
        </Col>
        <Col xs={12} lg={8} className="item">
          <div className="number">
            <img src="images/icons/user-rounded.png" className="icon" alt="Agregar"/>
            <p>02.</p>
          </div>
          <h4>Ingresá tus datos</h4>
          <p>Procede a la compra de los productos elegidos, ingresando tu mail y
          contraseña (si sos usuario registrado) o creando una cuenta nueva con
          tus datos personales. Tus datos quedarán seguros.</p>
        </Col>
        <Col xs={12} lg={8} className="item">
          <div className="number">
            <img src="images/icons/truck-rounded.png" className="icon" alt="Agregar"/>
            <p>03.</p>
          </div>
          <h4>Seleccioná la forma de envío</h4>
          <p>Podés seleccionar retirar tu producto en nuestra sucursales, envío a tu
          domicilio.</p>
        </Col>
        <Col xs={12} lg={8} className="item">
          <div className="number">
            <img src="images/icons/dollar-rounded.png" className="icon" alt="Agregar"/>
            <p>04.</p>
          </div>
          <h4>Elegí el medio de pago</h4>
          <p>Podrás seleccionar la forma de pago. En caso de que sea Mercado
          Pago, se abrirá una ventana emergente y allí podrás seleccionar abonar
          con tarjeta de Crédito, Débito , Pago Fácil y Rapipago o por transferencia
          bancaria. Una vez que hayas elegido el medio de pago, hacé clic en
          "Pagar".</p>
        </Col>
        <Col xs={12} lg={8} className="item">
          <div className="number">
            <img src="images/icons/check-rounded.png" className="icon" alt="Agregar"/>
            <p>05.</p>
          </div>
          <h4>Confirmá tu compra</h4>
          <p>Finalmente en la página de Confirmación de compra podés revisar
          toda la información de la compra. Luego hacé clic en "Confirmar
          compra".</p>
        </Col>
      </Row>
    
      <Row>
        <Col xs={12} md={8} className="important">
          <h5>IMPORTANTE:</h5>
          <p>Los envíos al interior del país y exterior, les llegará la guía del correo por mail.</p>
          <p>Los envíos en CABA o alrededores son entregados en el día entre las 16:00 y las 22:00hs.
si la compra se realizó antes de las 13:00 hs. Sino al día siguiente. Válido de Lunes a Sábados.</p>
          <p>Cuando seleccionan la opción de retiro por sucursal, se les informará por mail cuando
el pedido esté listo para ser retirado.</p>
        </Col>
      </Row>
    </Container>  
  )
}

export default HowToBuy