import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

const NotFound = () => {
  return (
    <Container fluid className="not-found">
      <Row>
        <Col xs={12}>
          <img src="images/404.jpg" className='img-fluid' alt="not found"/>
          <h1>Página no encontrada</h1>
          <Link to="/">
            Volver al home
          </Link>
        </Col>
      </Row>
    </Container>
  )
}
export default NotFound