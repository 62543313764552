import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { listOrders } from '../../actions/order'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import UserSideMenu from '../../components/user-side-menu'
import { Pagination } from '../../components/pagination'
import useRestoreCart from '../../hook/restore-cart'
import ButtonLoader from '../../components/button-loader'

const Orders = () => { 
  const { orders, isFetching, currentPage, numberOfPages } = useSelector(state => state.listOrders)
  const dispatch = useDispatch()
  const myRef = useRef(null)

  const [restoringCartOrderId, setRestoringCartOrderId] = useState(null)
  const [isRestoringCart, restoreCart] = useRestoreCart();

  useEffect(() => {
    dispatch(listOrders())
  }, [])

  const paginationClick = (pageNumber) => {
    dispatch(listOrders(pageNumber))
    myRef.current.scrollIntoView()
  }

  const handleRestoreToCart = (orderId) => {
    setRestoringCartOrderId(orderId)
    restoreCart(orderId)
  }

  return (
    <Container fluid className="inner-page desktop my-orders max-container">
      <div className="breadcrumb">
          Inicio / MI CUENTA
      </div>
      <h3>Mi Perfil</h3>
      <Row className="ml-0 mr-0">
        <UserSideMenu />
        <Col lg={7} className="profile-actions">
          <div className="green-box" ref={myRef}>
            <h4>Atención</h4>
            <p>Los siguientes datos no pueden ser modificados, son datos de facturación.</p>
            { 
              isFetching ?
                <h5 className='w-full text-center mt-5 mb-5'>Cargando...</h5>
              :
                orders && orders.length ==  0 ?
                  <Row><h4 style={{color: '#000', textAlign: 'center', width: '100%', marginTop:'20px', border: 'none'}}>No se realizaron pedidos</h4></Row>
                : 
                  <>
                    { 
                      orders.map((order, i) => 
                        <Row className="order-item" key={i}>
                          <Col lg={8} className="order-detail">
                            <p className="order-number">Pedido #{order.id}</p>
                            {
                              order.orderItems.map((orderItem,j) => 
                                <p className="order-title" key={j}>{orderItem.productTitle}... x{orderItem.quantity}</p>
                              )
                            }
                            <p className="order-delivery">Pedido el {moment(order.created_at).format('DD/MM/YYYY')} </p>
                            <p className="order-amount">Total: {order?.total.currency} {order.total.amount}</p>
                          </Col>
                          <Col lg={4} className="order-actions">
                            <Link to={`/cuenta/pedido/${order.id}`} className="purchase-detail">Ver compra</Link>
                            {/* <Link to={`/producto/${order?.orderItems[0]?.productSlug}`} className="purchase-again">Volver a comprar</Link> */}
                            <ButtonLoader 
                              isLoading={isRestoringCart && restoringCartOrderId == order.id}
                              loadingMessage="Agregando..."
                              type="button" 
                              className="purchase-again"
                              onClick={() => handleRestoreToCart(order.id)}>
                                Volver a comprar
                            </ButtonLoader> 
                          </Col>
                        </Row>
                      )
                    }
                    <Pagination
                      items={Array.from(Array(numberOfPages).keys())} 
                      active={currentPage} 
                      onClick={paginationClick}
                      totalPages={numberOfPages}/> 
                </>
            }
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Orders