import React, { useCallback, useState } from 'react'
import Pop
 from '../components/pop'
export const PopupContext = React.createContext()

export const PopupProvider = props => {
  const [popup, setPopup] = useState()
  
  const unSetPopup = useCallback(() => {
    setPopup()
  }, [setPopup])
  
  return (
    <PopupContext.Provider value={{ unSetPopup, setPopup }} {...props} >
      { props.children }
      { popup && <Pop message={popup} unSetPopup={unSetPopup} />}
    </PopupContext.Provider>
  )
}

export const usePopup = () => {
  const context = React.useContext(PopupContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a Provider')
  }
  return context
}