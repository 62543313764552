import { useState } from 'react'
import {useDispatch} from 'react-redux'
import { addCartItem, showCart } from '../actions/cart'
import { usePopup } from '../context/popup-context'
import ReactPixel from 'react-facebook-pixel'

const useAddToCart = () => {
  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const dispatch = useDispatch()
  const { setPopup } = usePopup()
    
  const addToCart = (productId, cartQuantity = 1) => {
    setIsAddingToCart(true)
    dispatch(addCartItem(productId,cartQuantity))
      .then((response) => {
        let cart = response.value;
        if(!localStorage.getItem('entelequia-cart-uuid') || localStorage.getItem('entelequia-cart-uuid') !== cart.uuid ){
          localStorage.setItem('entelequia-cart-uuid',cart.uuid)
        }
        setPopup('El producto se agregó al carrito correctamente!')
        dispatch(showCart())
        setIsAddingToCart(false)
        ReactPixel.init('261944151355410');
        ReactPixel.track('AddToCart',{ content_ids: [productId], content_type: 'product' }); 
      }).catch(error => {
        let msg = 'Error al agregar producto al carrito si el problema persiste, comuniquese con nostros.'
        if(error.response.status == 422){
            msg = error.response.data.errorMessage
        }
        setIsAddingToCart(false)
        setPopup(msg) 
      })
  }

  return [isAddingToCart, addToCart]
}

export default useAddToCart