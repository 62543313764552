import React, { useEffect, useState } from 'react'
import {Link, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { processGetnetPayment, processMercadoPagoPayment, processPaypalPayment } from '../actions/payment'
import history from '../history'

const CheckoutProcessPayment = props => {
  const params = useParams()
  const dispatch = useDispatch()
  const [message, setMessage] = useState('Procesando pago...')

  useEffect(() => {
    let { type, status } = params
    var searchParams = new URLSearchParams(window.location.search)
    if(type === 'mp'){
      let formData  = new FormData()
      searchParams.forEach((v,k) => formData.append(k,v))
      dispatch(processMercadoPagoPayment(status,formData))
        .then(response => {
            let order = response.value
            history.push('/cuenta/checkout-complete',{
                order: order
            })
        })
        .catch(error => {
          let {status, data} = error.response
          setMessage(data.message)
        })
    } else if(type === 'getnet'){

      const entelequia_getnet_order = JSON.parse(localStorage.getItem('entelequia_getnet_order') ?? '{}');

      if(entelequia_getnet_order.order_id && entelequia_getnet_order.payment_intent_id){

        dispatch(processGetnetPayment(status, entelequia_getnet_order.order_id, entelequia_getnet_order.payment_intent_id))
          .then(response => {
            let order = response.value
            history.push('/cuenta/checkout-complete',{
              order: order
            })
          })
          .catch(error => {
            let {status, data} = error.response
            setMessage(data.message ?? 'Error al procesar el pago con Getnet. Por favor, si el pago se realizó correctamente, contáctenos.')
          })

      }else{
        setMessage('Error al procesar el pago con Getnet. Por favor, si el pago se realizó correctamente, contáctenos.')
      }
      
    } else  {
      let token = searchParams.get('token')
      let payerId = searchParams.get('PayerID')
      dispatch(processPaypalPayment(token,payerId))
        .then(response => {
          let order = response.value
          history.push('/cuenta/checkout-complete',{
            order: order
          })
        })
        .catch(error => {
          let {status, data} = error.response
          setMessage(data.message)
        })
    }
  }, [])

  return (
      <div className="pt-5 mt-5 pb-5 mb-5 text-center checkout-payment">
        <h3 className="pt-5 mt-5 mb-5 ">{message}</h3>
        {message !== 'Procesando pago...'? 
            <Link to="/cuenta" className="payment-button">Volver a Mi Cuenta</Link> : ''}
      </div>
  )
}

export default CheckoutProcessPayment