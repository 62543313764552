import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import ProductMiniCard from './product-mini-card'
import ProductCard from './product-card'

const CarouselGrey = ({products, mini = false}) => {
  const responsiveMini = {
    0: {
      items: 1,
    },
    760: {
      items: 2,
    },
  }

  const responsive = {
    0: {
      items: 1,
    },
    760: {
      items: 2,
    },
    992: {
      items: 3,
    }
  }

  return (
    <OwlCarousel className='owl-theme' margin={10} dots={true} nav={true} loop responsive={mini? responsiveMini : responsive}>
      {mini ? 
        <>
          {products?.map((e,i) => 
            <ProductMiniCard key={i} product={e} className="item with-border"/>
          )}
        </>
      :
        <>
          {products?.map((e,i) => 
            <ProductCard key={i} product={e} className="item with-border"/>
          )}
        </>
      }
    </OwlCarousel>
  )

}

export default CarouselGrey