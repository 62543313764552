import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

const AboutUs = () => {
  return (
    <Container fluid className="inner-page about-us">
      <div className="breadcrumb pl-0"><Link to="/">Inicio</Link> / NOSOTROS</div>
      <h3>Sobre nosotros</h3>
      <Row>
        <Col xs={12} lg={8} className="item">
          <img src="images/icons/book.svg" className="icon" alt="Variedad"/>
          <div className="text-cont">
            <h4>Variedad para <strong>buscar</strong>, <strong>seleccionar</strong> y <strong>no parar de leer.</strong></h4>
            <p><strong>Entelequia</strong> es una comiquería y librería especializada en la importación, venta
            y distribución de historietas argentinas, estadounidenses y europeas, manga,
            animé, libros de ilustración, música, diseño, animación, cine y efectos especiales.
            Contamos con el más amplio catálogo de literatura fantástica, de horror y
            ciencia ficción y una amplia oferta de merchandising, figuras de acción, posters,
            trading cards e ítems coleccionables para los amantes del género.</p>
          </div>
        </Col>
        <Col xs={12} lg={8} className="item">
          <img src="images/icons/star.svg" className="icon" alt="Variedad"/>
          <div className="text-cont">
            <h4><strong>Historia</strong> y <strong>experiencia.</strong></h4>
            <p>Somos pioneros en la venta de productos de culto en el país. Con más de 40
            años de experiencia en el rubro, nos diferenciamos por nuestro servicio personalizado,
            el trato amable con el cliente y nuestra pasión por el arte y el mundo
            fantástico.</p>
          </div>
        </Col>
        <Col xs={12} lg={8} className="item">
          <img src="images/icons/return.svg" className="icon" alt="Variedad"/>
          <div className="text-cont" >
            <h4><strong>Envíos</strong> y <strong>devoluciones.</strong></h4>
            <p>Realizamos envios a todo el país con más de un proveedor logístico. Además
            hacemos envíos por medio de DHL Courrier.</p>
          </div>
        </Col>
        <Col xs={12} lg={8} className="item">
          <img src="images/icons/shop.svg" className="icon" alt="Variedad"/>
          <div className="text-cont">
            <h4>Contamos con <strong>tiendas para que nos visites.</strong></h4>
            <p>Contamos con una tienda en el centro de Microcentro y otra en Zona Norte
            Belgrano..</p>
            <Link to="/contacto">Cómo llegar a ellas</Link>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default AboutUs