import React, {useState, useEffect} from 'react'
import { ListGroup, Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { findProductBrands, findProductAuthors } from '../actions/product'
import { findTreeCategories } from '../actions/category'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import Slider from 'rc-slider'
import RecursiveDropdown from './recursive-dropdown'
const { Range } = Slider;

const MAX_PRICE_SELECTABLE = 100000; // cambiar tmb en ProductPublicFilter

const SearchFilter = ({ handleAddFilter, handleSelectCategory, appliedFilters}) => {
  const [minPrice, setMinPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(MAX_PRICE_SELECTABLE)
  const [brandsOptions, setBrandsOptions] = useState([])
  const [categories, setCategories] = useState([])
  // const [authorsOptions, setAuthorsOptions] = useState([])
  const dispatch = useDispatch()

  console.log("+++++++++++++++++++++++++++++++++++++")
  console.log(appliedFilters)
  
  useEffect(() => {
    dispatch(findProductBrands())
      .then(response =>{
        let options = response.value.brands.map((e,i) => ({'value': e.slug, 'label': e.name}))
        setBrandsOptions(options)
      })
    dispatch(findTreeCategories())
      .then(response =>{
        let _categories = response.value
        setCategories(_categories)
      })
    // dispatch(findProductAuthors())
    //   .then(response =>{
    //     let options = response.value.authors.map((e,i) => ({'value': e.slug, 'label': e.name}))
    //     setAuthorsOptions(options)
    //   })
    if(localStorage.getItem('entelequia-currency') == 'USD'){
      setMinPrice(5)
      setMaxPrice(100)
    }
  }, [])


  const loadAuthorsOptions = (inputValue, callback) => {
      dispatch(findProductAuthors(inputValue))
      .then(response =>{
        let options = response.value.authors.map((e,i) => ({'value': e.slug, 'label': e.name}))
        callback(options)
      })
  }

  useEffect(() => {
    if(appliedFilters.precioMin){
      setMinPrice(appliedFilters.precioMin)
    }
    if(appliedFilters.precioMax){
      setMaxPrice(appliedFilters.precioMax)
    }
  },[appliedFilters])

  const onClickFilter = value => {
    console.log(value)
    handleAddFilter(value)
  }

  console.log("-----------------------------")
  console.log(brandsOptions.length)
  console.log(categories.length)
  // console.log(authorsOptions.length)

  return (
    <>
      <div className="filter-item">
        <h4>Categorías</h4>
        <RecursiveDropdown menu={categories} onItemClick={handleSelectCategory}/>
      </div>
      <div className="filter-item">
        <h4>Filtrar por Precio</h4>
        <Form.Group controlId="priceRange" className="priceGroup">
          {/* <Range
            allowCross={false} 
            defaultValue={[minPrice, maxPrice]} 
            min={localStorage.getItem('entelequia-currency') == 'USD'? 1: 0}
            max={localStorage.getItem('entelequia-currency') == 'USD'? 1000 : MAX_PRICE_SELECTABLE} 
            step={localStorage.getItem('entelequia-currency') == 'USD'? 5 : 1000} 
            onChange={prices => { setMinPrice(prices[0]); setMaxPrice(prices[1])}} />           */}
          {/* <span>Precio: <strong>${minPrice} - { (MAX_PRICE_SELECTABLE === maxPrice) ? "∞" : `$${maxPrice}` }</strong></span> */}
          <input 
            type="number" 
            name="minPrice" 
            className="input-price-range"
            value={minPrice}
            onChange={(evt) => setMinPrice(evt.target.value)}
            placeholder='Mínimo'
            required/>
          <span>-</span>
          <input 
            type="number" 
            name="maxPrice" 
            className="input-price-range"
            value={maxPrice}
            onChange={(evt) => setMaxPrice(evt.target.value)}
            placeholder='Máximo'
            required/>
          <Button className="btn-secondary" onClick={() => onClickFilter({precioMin: minPrice, precioMax: maxPrice})}>FILTRAR</Button>
        </Form.Group>

          <div className={appliedFilters.ofertas === 'true' ? 'offer-switch-container selected' : 'offer-switch-container' } >
            <Form.Check // prettier-ignore
                type="switch"
                id="offer-switch"
                label="SOLO OFERTAS"
                onChange={(evt) => onClickFilter({ofertas: evt.target.checked})}
                checked={appliedFilters.ofertas === 'true'}
              />
          </div>

      </div>
     
      <div className="filter-item">
        <h4>Idioma</h4>
        <ListGroup>
            <ListGroup.Item><button onClick={() => onClickFilter({idioma: 'español'})} className={appliedFilters.idioma === 'español' ? 'selected' : '' }>Español</button></ListGroup.Item>
            <ListGroup.Item><button onClick={() => onClickFilter({idioma: 'Ingles'})} className={appliedFilters.idioma === 'Ingles' ? 'selected' : '' }>Ingles</button></ListGroup.Item>
            <ListGroup.Item><button onClick={() => onClickFilter({idioma: 'Japones'})} className={appliedFilters.idioma === 'Japones' ? 'selected' : '' }>Japones</button></ListGroup.Item>
        </ListGroup>
      </div>
    
      <div className="filter-item">
        <h4>Formato</h4> 
        <ListGroup>
          <ListGroup.Item><button onClick={() => onClickFilter({formato: 'grapa'})} className={appliedFilters.formato === 'grapa' ? 'selected' : '' } >Grapa</button></ListGroup.Item>
          <ListGroup.Item><button onClick={() => onClickFilter({formato: 'rustica'})} className={appliedFilters.formato === 'rustica' ? 'selected' : '' }>Rústica</button></ListGroup.Item>
          <ListGroup.Item><button onClick={() => onClickFilter({formato: 'rustica_con_solapas'})} className={appliedFilters.formato === 'rustica_con_solapas' ? 'selected' : '' }>Rústica con solapas</button></ListGroup.Item>
          <ListGroup.Item><button onClick={() => onClickFilter({formato: 'tapa_dura'})} className={appliedFilters.formato === 'tapa_dura' ? 'selected' : '' }>Tapa dura</button></ListGroup.Item>
          <ListGroup.Item><button onClick={() => onClickFilter({formato: 'tapa_blanda'})} className={appliedFilters.formato === 'tapa_blanda' ? 'selected' : '' }>Tapa blanda</button></ListGroup.Item>
        </ListGroup>
      </div>
    
      <div className="filter-item">
        <h4>Marca</h4>
        <Select
          options={brandsOptions}
          onChange={option => onClickFilter({editorial: option.value})}
          placeholder="Seleccionar marca..."/>
      </div>
      
    
      <div className="filter-item">
        <h4>Autor</h4>
        <AsyncSelect
          // options={authorsOptions}
          onChange={option => onClickFilter({autor: option.value})}
          cacheOptions
          loadOptions={loadAuthorsOptions}
          defaultOptions
          placeholder="Seleccionar autor..."/>
      </div>

    </>
  )
}

export default SearchFilter