import React from "react"

const InputSpinner = ({quantity = 1, onClickHandler}) => {

  const onClickMinus = () => {
    let newQuantity = quantity - 1
    if(newQuantity < 1){
      newQuantity = 1
    } else{
      onClickHandler(newQuantity)
    }
  }

  const onClickPlus = () => {
    let newQuantity = quantity + 1
    onClickHandler(newQuantity)
  }

  return (
    <div className="input-group quantity-spinner">
      <span className="input-group-btn">
        <button type="button" className="btn btn-default btn-number" onClick={onClickMinus}>
          <span className="glyphicon glyphicon-minus">-</span>
        </button>
      </span>
      <input type="text" name="quantity" className="form-control input-number" value={quantity} readOnly reamin="1" max="10"/>
      <span className="input-group-btn">
        <button type="button" className="btn btn-default btn-number" onClick={onClickPlus}>
          <span className="glyphicon glyphicon-plus">+</span>
        </button>
      </span>
    </div>
  )
}

export default InputSpinner