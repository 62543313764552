import React, { useState, useEffect } from 'react'
import {useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import ProductCard from '../components/product-card'
import { getTag } from '../actions/tag'


const Tags = () => {
  const {tag, isFetching} = useSelector(state => state.getTag)
  const [products, setProducts] = useState([])
  const [tagName, setTagName] = useState('')
  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    let tagSlug = params.slug
    dispatch(getTag(tagSlug))
  },[])

  useEffect(() => {
    if(tag){
      let {name, products: _products} = tag
      setProducts(_products)
      setTagName(name)
    }
  },[tag])

  return (
    <Container className="inner-page contact">
      <div className="breadcrumb pl-0"><Link to="/">Inicio</Link> / TAGS</div>
      <h3 className="mb-5">{tagName}</h3>
      <Row>
        {
          isFetching === true ?
            <Col>
              <h3 className="w-full mt-5 mb-5 pt-5 pb-5 border-0 text-center">Cargando...</h3>
            </Col>
          :
            products.length > 0 ? products.map((e,i) => 
              <Col key={i} xs={6} sm={4} md={3} className="mb-4">
                <ProductCard product={e}/>
              </Col>
            )  
            : 
            <Col className="pt-5 pb-5"><h4 className="text-center mt-5 mb-5">No se encontraron productos</h4></Col>
        }
      </Row>
    </Container>
  )
}

export default Tags