import { useState } from "react"
import { Container, Row, Col, FormGroup, ListGroup } from "react-bootstrap"
import { Link } from "react-router-dom"
import ButtonLoader from "../components/button-loader"
import { sendContact } from "../actions/contact"
import { useDispatch } from "react-redux"

const Contact = () => {
  const [contact, setContact ] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
  })
  const dispatch = useDispatch()
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isSending, setIsSending] = useState(false)
  
  const  onSendForm = (evt) => {
    evt.preventDefault()
    setIsSending(true)
    dispatch(sendContact(contact))
      .then(() => {
        setErrorMessage(null)
        setSuccessMessage('Su consulta ha sido enviada. A la brevedad le estaremos respondiendo. Gracias por comunicarse con nosotros!')
        setIsSending(false)
      }).catch(()=>{
        setSuccessMessage(null)
        setErrorMessage('No hemos podido enviar su consulta. Si el problema persiste comuniquese con nosotros a: info@entelequia.com.ar')
        setIsSending(false)
      })
  }

  return (
    <Container fluid className="inner-page contact-page max-container">
      <div className="breadcrumb pl-0"><Link to="/">Inicio</Link> / CONTACTO</div>
      <h3>Contactanos</h3>
      <Row className="maps">
        <Col xs={12} sm={{span: 10, offset: 1}} lg={{ span:6, offset: 0}}>
          <div className="green-box mb-5 mb-lg-0">
            <div className="gmap">
              <iframe 
                title="Entelequia Centro"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.9763450075634!2d-58.38841778423691!3d-34.60475966506139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccac4373c8a83%3A0x9ea1064e27cbbdef!2sUruguay%20341%2C%20C1015%20ABG%2C%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1632405527474!5m2!1ses-419!2sar" 
                width="600" 
                height="450" 
                style={{border:"0"}} 
                allowfullscreen="" 
                loading="lazy"></iframe>
            </div>
            <ListGroup>
              <ListGroup.Item>
                <div className="img-cont">
                  <img src="images/icons/marker_green.png" alt="Ubicación"/>
                </div>
                Uruguay 341
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="img-cont">
                  <img src="images/icons/phone_green.png" alt="Teléfono"/>
                </div>
                (011) 4372-7282
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="img-cont">
                  <img src="images/icons/envelop_green.png" alt="Mail"/>
                </div>
                info@entelequia.com.ar
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Col>
        <Col xs={12} sm={{span: 10, offset: 1}} lg={{ span:6, offset: 0}}>
          <div className="green-box">
            <div className="gmap">
              <iframe 
                title="Entelequia Belgrano"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.6194895496437!2d-58.460449584237814!3d-34.56318846286291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5d46c354369%3A0xe770bd1de5af2888!2sAv.%20Juramento%202584%2C%20C1428%20CABA!5e0!3m2!1ses-419!2sar!4v1632405695898!5m2!1ses-419!2sar" 
                width="600" 
                height="450" 
                style={{border:"0"}} 
                allowfullscreen="" 
                loading="lazy"></iframe>
            </div>
            <ListGroup>
              <ListGroup.Item>
                <div className="img-cont">
                  <img src="images/icons/marker_green.png" alt="Ubicación"/>
                </div>
                Juramento 2584
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="img-cont">
                  <img src="images/icons/phone_green.png" alt="Teléfono"/>
                </div>
                (011) 4788-4521
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="img-cont">
                  <img src="images/icons/envelop_green.png" alt="Mail"/>
                </div>
                belgrano@entelequia.com.ar
              </ListGroup.Item>
            </ListGroup>
          </div>
        </Col>
      </Row>
      <form onSubmit={onSendForm}>
        <Row className="frm ml-sm-5 mr-sm-5 ml-lg-0 mr-lg-0" >
          <Col xs={12}>
            <h4>Contacto</h4>
          </Col>
          {
            successMessage &&
            <Col xs={12} className="mb-2">
              <h5 style={{color: 'green'}}>{successMessage}</h5>
            </Col>
          }
          {
            errorMessage &&
            <Col xs={12} className="mb-2">
              <h5 style={{color: 'red'}}>{errorMessage}</h5>
            </Col>
          }
          <Col md={6}>
            <FormGroup>
              <label for="name">Nombre:</label>
              <input 
                type="text" 
                name="name"
                className="form-control" 
                value={contact.name}
                onChange={(evt) => setContact({...contact, name: evt.target.value})}
                required/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label for="phone">Télefono:</label>
              <input 
                type="text" 
                name="phone" 
                className="form-control"
                value={contact.phone}
                onChange={(evt) => setContact({...contact, phone: evt.target.value})}
                required/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label for="email">Email:</label>
              <input 
                type="email" 
                name="email" 
                className="form-control" 
                value={contact.email}
                onChange={(evt) => setContact({...contact, email: evt.target.value})}
                required/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <label for="subject">Asunto:</label>
              <input 
                type="text" 
                name="subject" 
                className="form-control" 
                value={contact.subject}
                onChange={(evt) => setContact({...contact, subject: evt.target.value})}
                required/>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <p>Mensaje</p>
            <textarea 
              className="form-control"
              value={contact.message}
              onChange={(evt) => setContact({...contact, message: evt.target.value})} 
              required>
            </textarea>
          </Col>
          <Col xs={12} className="text-center">
            <ButtonLoader
              isLoading={isSending}
              loadingMessage="Enviando..."
              className="btn-primary-green">
                Enviar
            </ButtonLoader>
          </Col>
        </Row>
      </form>
    </Container>
  )
}

export default Contact