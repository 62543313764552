import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import { findOfferProducts } from '../actions/product'
import { Container, Row, Col } from 'react-bootstrap'
import ProductCardOffer from '../components/product-card-offer'
import {Pagination} from '../components/pagination'


const Offers = () => {
  const { products, isFetching, currentPage, numberOfPages} = useSelector(state => state.findOfferProducts)
  const dispatch = useDispatch()
  const myRef = useRef(null)

  useEffect(() =>{
    dispatch(findOfferProducts())
  },[])

  const paginationClick = (pageNumber) => {
    dispatch(findOfferProducts(pageNumber))
    myRef.current.scrollIntoView()
  }

  return (
    <Container className="inner-page contact offers-page">
      <div className="breadcrumb pl-0"><Link to="/">Inicio</Link> / OFERTAS</div>
      <h3 className="mb-5" ref={myRef}>Ofertas</h3>
      {  
        isFetching === true ?
          <h3 className="w-full mt-5 mb-5 pt-5 pb-5 border-0 text-center">Cargando...</h3>
        :
          products != null && products.length > 0 ? 
            <>
              <Row>
                { products.map((e,i) => 
                    <Col key={i} md={6} lg={4} className="mb-4">
                      <ProductCardOffer product={e}/>
                    </Col>
               )}
              </Row>
              <Row>
                <Pagination
                  items={Array.from(Array(numberOfPages).keys())} 
                  active={currentPage} 
                  onClick={paginationClick}
                  totalPages={numberOfPages}
                />
              </Row>
            </>  
        : 
          <Row>
            <Col className="pt-5 pb-5"><h4 className="text-center mt-5 mb-5">No se encontraron productos</h4></Col>
          </Row>
      }
    </Container>
  )
}

export default Offers