import React,{useState, useEffect} from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Carousel as ProductCarousel } from '../components/carousel'

const MainCategories = ({categories}) => {
  const [selectedTab, setSelectedTab] = useState()
  
  useEffect(() => {
    if(categories.length){
        setSelectedTab(categories[0].name)
    }
  }, [categories])
  
  return (
    <Tabs
      id="main-categories-tab"
      activeKey={selectedTab}
      onSelect={(k) => setSelectedTab(k)}
      className="">
      {
        categories?.map((category,i) => 
          <Tab key={i} eventKey={category.name} title={category.name}>
            <ProductCarousel products={category.products}/>
              <Link className="see-more" to={`/productos/${category.slug}`}>Ver más</Link>
          </Tab>
        )
      }
    </Tabs>
  )
}

export default MainCategories