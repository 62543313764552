import React from 'react'
import { FaSpinner } from 'react-icons/fa'

const ButtonLoader = props => {
  let { isLoading, loadingMessage, children, className, ...elProps} = props
  
  return (
    <button
      className={`${className} button-loader`}
      {...elProps}    
      disabled={isLoading}>
      { isLoading ? (
          <>
            <FaSpinner icon="spinner" className="button-spinner mr-2"/> 
            <span>{loadingMessage}</span>
          </>
        ) : 
         children
      }
  </button>      
  )
}

export default ButtonLoader