import React, {useState} from "react"
import { useEffect } from "react"
import { Dropdown} from "react-bootstrap"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { deleteShopNotifications, getShopNotifications } from "../actions/shop-notification"
import { PUBLIC_URL } from "../helpers/domain"

const NotificationDropdown = () => {
  const {profile} = useSelector(state => state.showClientProfile)
  const [notifications, setNotifications] = useState([])
  const dispatch = useDispatch()
  let publicUrl = PUBLIC_URL

  useEffect(() => {
    // if(profile){
      dispatch(getShopNotifications())
        .then(response => {
          setNotifications(response.value)  
        })
        .catch(error => console.log(error))
    // }
  },[])

  useEffect(() => {
    // if(profile){
      dispatch(getShopNotifications())
      .then(response => {
        setNotifications(response.value)  
      })
    // } else {
      // setNotifications([])
    // }
  },[profile])

  const onDeleteNotification = (notificationId) => {
    dispatch(deleteShopNotifications(notificationId))
      .then(response => {
        setNotifications(response.value.data)
      })
  }

  return (
    <div className="notification-dropdown flex-row">
      { 
        notifications?.length > 0 &&
          <span className="notif-count">{notifications.length}</span>
      }
      <Dropdown>
        <Dropdown.Toggle id="dropdown-custom-components">
          <img src={`${publicUrl}/images/icons/bell.png`} alt="" className="img-fluid"/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.ItemText className="title">
            <h5>Notificaciones</h5>
          </Dropdown.ItemText>
          { notifications?.length === 0 ?
              <Dropdown.ItemText className="pt-3">
                <p className="text-center pr-0">No tiene nuevas notificaciones</p>
              </Dropdown.ItemText>
            :
              notifications?.map((e,i) =>
                <Dropdown.ItemText key={i}>
                    <p>{e.message}</p>
                    <button 
                      className="delete-notification" 
                      onClick={() => onDeleteNotification(e.id)}
                      title="Borrar notificación">
                        <img 
                          src={`${publicUrl}/images/icons/trash-icon.png`} 
                          alt="Borrar" 
                          className="img-fluid "/>
                    </button>
                </Dropdown.ItemText>
              )
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default NotificationDropdown