import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Row, Col, Button, FormGroup } from 'react-bootstrap'
import { renewPassword } from '../../actions/auth'
import Alert from '../../components/alert'

const RenewPassword = props => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [message, setMessage] = useState(null)
  const dispatch = useDispatch()
  
  const onFormSubmit = (evt) => {
    evt.preventDefault()
    console.log(password)
    console.log(passwordConfirmation)
    if(password !== passwordConfirmation){
      setMessage('La contraseña y su confirmación no coinciden')
    } else {
      dispatch(renewPassword(password, passwordConfirmation))
        .then(() => {
          setMessage('Su contraseña ha sido cambiado con éxito!')
        })
        .catch((error) => {
          setMessage('Se produjo un error al restablecer la contraseña. Si el problema persiste, comuniquese con nostros.')
        })
      }
    }

  return (
    <Container fluid className="inner-page connect max-container">
      <Row className="ml-0 mr-0 mt-5 mb-4">
        <Col lg={{ span: 8, offset: 2}} className="login">
          <div className="green-box">
            <h4 className='text-center'>Restaurar la clave de acceso</h4>
            { message && 
              <div className="w-full mb-4">
                <Alert color="bg-transparent border-red-500 text-red-500" borderLeft raised>
                  {message}
                </Alert>
              </div>
            }
            <p>Ingresá una nueva clave para tu cuenta de usuario</p>                     
            <form onSubmit={onFormSubmit}>
              <FormGroup>
                <label className="form-label">Contraseña</label>      
                <input type="password" 
                  className='form-control'
                  name="password" 
                  value={password} 
                  placeholder="Ingrese una nueva contraseña" 
                  onChange={e => setPassword(e.target.value)} 
                  required/>
              </FormGroup>
              <FormGroup>
                <label className="form-label"> Confirmar contraseña</label>
                <input type="password" 
                  className='form-control'
                  name="passwordConfirmation" 
                  value={passwordConfirmation} 
                  placeholder="Confirmar nueva contraseña" 
                  onChange={e => setPasswordConfirmation(e.target.value)} 
                  required/>
              </FormGroup>
              <Button type="submit">Aceptar</Button>
            </form>
          </div>        
        </Col>
      </Row>
    </Container>
  )
}

export default RenewPassword