import axios from 'axios'

export const ADD_FAVORITE = 'ADD_FAVORITE'
export const LIST_FAVORITES = 'LIST_FAVORITES'
export const REMOVE_FAVORITES = 'REMOVE_FAVORITES'


export const addFavorite = (productId) => ({
  type: ADD_FAVORITE,
  payload: axios.put(`/account/favorites/${productId}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const listFavorites = (page = 1) => ({
  type: LIST_FAVORITES,
  payload: axios.get('/account/favorites', {
    params: {
      page
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const removeFavorite = (productId) => ({
  type: REMOVE_FAVORITES,
  payload: axios.delete(`/account/favorites/${productId}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})