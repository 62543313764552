import React from 'react'
import {Link} from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { logout } from '../../helpers/logout'
import UserSideMenu from '../../components/user-side-menu'
import { useSelector } from 'react-redux'

const Desktop = () => { 
  const {profile} = useSelector(state => state.showClientProfile)

  return (
    <Container fluid className="inner-page desktop max-container">
      <div className="breadcrumb">
          Inicio / MI CUENTA
      </div>
      <h3>Mi Perfil</h3>
      <Row className="ml-0 mr-0">
        <UserSideMenu />
        <Col lg={7} className="profile-actions">
          <div className="poster green">
            <p>Hola {profile?.name}!</p>
          </div>
          <div className="green-box">
            <h4>Desde el panel de control de tu cuenta podes...</h4>
            <Row>
              <Col>
                <Link to="/cuenta/mis-pedidos">Ver tus pedidos recientes</Link>
              </Col>
              <Col>
                <Link to="/cuenta/direccion">Gestionar tus direcciones</Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <Link to="/cuenta/perfil">Editar tu contraseña y los detalles de tu cuenta</Link></Col>
            </Row>
            <Row>
              <Col>
                { profile?.promotion_points !=null &&
                  <p className='entelequia-pesos'>Entelequia PE$O$: {profile?.promotion_points}</p>
                }
              </Col>
            </Row>
          </div>
          <div className="poster red">
            <p>¿No eres {profile?.name}?</p>
            <button onClick={()=> logout()}>Cerrar sesión</button>
          </div> 
        </Col>
      </Row>
    </Container>
  )
}

export default Desktop