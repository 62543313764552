import 'axios'
import axios from 'axios'

export const GET_GIFTCARDS = 'GET_GIFTCARDS'

export const getGiftCards = () => ({
  type: GET_GIFTCARDS,
  payload: axios.get('/giftcards-list', {
    params: {
      currency: localStorage.getItem('entelequia-currency')
    }
  })
  .then(response => response.data)
  .catch(error => Promise.reject(error))
})