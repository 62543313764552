import axios from 'axios'

export const SHOW_PRODUCT = 'SHOW_PRODUCT'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const SEARCH_PRODUCTS_BY_CATEGORY = 'SEARCH_PRODUCTS_BY_CATEGORY'
export const FIND_LATEST_PRODUCTS = 'FIND_LATEST_PRODUCTS'
export const FIND_RECOMMENDED_PRODUCTS = 'FIND_RECOMMENDED_PRODUCTS'
export const FIND_OFFER_PRODUCTS = 'FIND_OFFER_PRODUCTS'
export const FIND_PRESALE_PRODUCTS = 'FIND_PRESALE_PRODUCTS'
export const FIND_SPECIAL_OFFERS = 'FIND_SPECIAL_OFFERS'
export const FIND_PRODUCT_BRANDS = 'FIND_PRODUCT_BRANDS'
export const GET_PRODUCT_SUGGESTION = 'GET_PRODUCT_SUGGESTION'
export const FIND_PRODUCT_AUTHORS = 'FIND_PRODUCT_AUTHORS'

export const showProduct = productId => ({
  type: SHOW_PRODUCT,
  payload: axios.get(`/product/${productId}`,{
    params: {
      currency: localStorage.getItem('entelequia-currency')
    }
  } )
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const searchProducts = (filters, category = '') => ({
  type: SEARCH_PRODUCTS,
  payload: axios.get(`/products-list/${category}`,{
    params: {
      ...filters,
      currency: localStorage.getItem('entelequia-currency')
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const searchProductsByCategory = (category, filters = null) => ({
  type: SEARCH_PRODUCTS_BY_CATEGORY,
  payload: axios.get(`/products/category/${category}`,{
    params: {
      ...filters,
      currency: localStorage.getItem('entelequia-currency')
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const findLatestProducts = (page = 1) => ({
  type: FIND_LATEST_PRODUCTS,
  payload: axios.get('/products/latest', {
    params: {
      currency: localStorage.getItem('entelequia-currency'),
      page,
    }
  } )
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const findOfferProducts = (page = 1) => ({
  type: FIND_OFFER_PRODUCTS,
  payload: axios.get('/products/offer',{
    params: {
      currency: localStorage.getItem('entelequia-currency'),
      page
    }
  } )
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const findPresaleProducts = (page = 1) => ({
  type: FIND_PRESALE_PRODUCTS,
  payload: axios.get('/products/presale',{
    params: {
      currency: localStorage.getItem('entelequia-currency'),
      page
    }
  } )
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const findSpecialOffers = (page = 1) => ({
  type: FIND_SPECIAL_OFFERS,
  payload: axios.get('/oferta-ninez',{
    params: {
      currency: localStorage.getItem('entelequia-currency'),
      page
    }
  } )
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const findRecommendeProducts = () => ({
  type: FIND_RECOMMENDED_PRODUCTS,
  payload: axios.get('/products/recommended', {
    params: {
      currency: localStorage.getItem('entelequia-currency')
    }
  }  )
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const findProductBrands = () => ({
  type: FIND_PRODUCT_BRANDS,
  payload: axios.get('/products/brands', {
    params: {
      currency: localStorage.getItem('entelequia-currency')
    }
  }  )
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const findProductAuthors = (search = null) => ({
  type: FIND_PRODUCT_AUTHORS,
  payload: axios.get('/products/authors', {
    params: {
      search
    }
  }  )
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const getProductsSuggestion = (search = null) => ({
  type: GET_PRODUCT_SUGGESTION,
  payload: axios.get(`/products/suggestions?search=${search}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})