import React, { useState } from "react";
// import PropTypes from 'prop-types'

// export const PageWithText = ({
// 	activeClassNames = 'btn btn-default text-button active-button',
// 	inactiveClassNames = 'btn btn-default text-button',
// 	children,
// 	active = false,
// 	onClick
// }) => {
// 	if (active) {
// 		return (
// 			<button onClick={onClick} className={activeClassNames}>
// 				{children}
// 			</button>
// 		)
// 	}
// 	return (
// 		<button onClick={onClick} className={inactiveClassNames}>
// 			{children}
// 		</button>
// 	)
// }

export const Page = ({
	activeClassNames = 'btn page active-button',
	inactiveClassNames = 'btn page',
	children,
	active = false,
	title = '',
	onClick
}) => {
	if (active) {
		return (
			<button onClick={onClick} className={activeClassNames} title={title}>
				{children}
			</button>
		)
	}
	return (
		<button onClick={onClick} className={inactiveClassNames} title={title}>
			{children}
		</button>
	)
}

export const Pages = ({ items, active, onClick }) => (
	<>
		{items.length > 1 &&
			items.map(i => (
				<Page onClick={(e) => onClick(i + 1)} active={i + 1 === active ? true : false} key={i}>
					{i + 1}
				</Page>
			))}
	</>
)

// Pages.propTypes = {
// 	items: PropTypes.array.isRequired,
// 	active: PropTypes.number.isRequired
// }

// export const Pagination = ({
// 	items,
// 	active,
// 	previous = null,
// 	next = null,
// 	totalPages = 1,
// 	icons = false,
// 	onClick
// }) => {
// 	if (icons) {
// 		return (
// 			<div className="flex flex-wrap items-center justify-start space-x-2 pagination mt-4">
// 				{previous && <Page onClick={onClick}>{previous}</Page>}
// 				<Pages onClick={onClick} items={items} active={active} />
// 				{next && <Page onClick={onClick}>{next}</Page>}
// 			</div>
// 		)
// 	}
// 	return (
// 		<div className="flex flex-wrap items-center justify-start space-x-2 pagination mt-4">
// 			{/* <PageWithText onClick={() => onClick(1)} title="Primera">&lt;&lt;</PageWithText>
// 			{active !== 1 && <PageWithText onClick={() => onClick(active - 1)} title="Previo">&lt;</PageWithText>}
// 			<button onClick={onClick} className="btn active-button">
// 				{active}
// 			</button>
// 			<span>de {totalPages}</span>
// 			{active + 1 <= totalPages && <PageWithText onClick={() => onClick(active + 1)} title="Siguiente">&gt;</PageWithText>}
// 			<PageWithText onClick={() => onClick(totalPages)} title="Ultima">&gt;&gt;</PageWithText> */}
// 		</div>
// 	)
// }

// Pagination.propTypes = {
// 	items: PropTypes.array.isRequired,
// 	active: PropTypes.number.isRequired,
// 	//previous: PropTypes.any.isRequired,
// 	//next: PropTypes.any.isRequired,
// 	icons: PropTypes.bool
// }

export const Pagination = ({
	items,
	active,
	previous = null,
	next = null,
	totalPages = 1,
	icons = false,
	onClick
}) => {
	const [customPage, setCustomPage] = useState("");

	if (icons) {
		return (
			<div className="flex flex-wrap items-center justify-start space-x-2 pagination mt-4">
				{previous && <Page onClick={onClick}>{previous}</Page>}
				<Pages onClick={onClick} items={items} active={active} />
				{next && <Page onClick={onClick}>{next}</Page>}
			</div>
		)
	}

	const handleCustomPageSubmit = (e) => {
		if (e.key === "Enter" || e.type === "click") {
			const pageNumber = parseInt(customPage, 10);
			if (pageNumber >= 1 && pageNumber <= totalPages) {
				onClick(pageNumber);
			}
		}
	};

	const renderEllipsis = (ellipsisKey) => (
		<span style={{ color: "#6b7280", padding: "0 4px" }} key={ellipsisKey}>...</span>
	);

	const renderPage = (page) => (
		<button
			key={page}
			style={{
				padding: "4px 8px",
				borderRadius: "4px",
				backgroundColor: active === page ? "#3b82f6" : "#e5e7eb",
				color: active === page ? "#ffffff" : "#374151",
				border: "none",
				cursor: "pointer",
				transition: "background-color 0.2s",
				margin: "0 4px",
			}}
			onClick={() => onClick(page)}
			onMouseOver={(e) =>
				active !== page && (e.currentTarget.style.backgroundColor = "#d1d5db")
			}
			onMouseOut={(e) =>
				active !== page && (e.currentTarget.style.backgroundColor = "#e5e7eb")
			}
		>
			{page}
		</button>
	);

	const paginationItems = [];
	paginationItems.push(renderPage(1)); // Primera página

	if (active > 3) {
		paginationItems.push(renderEllipsis("ell1")); // Ellipsis antes del rango actual
	}

	for (let i = active - 2; i <= active + 2; i++) {
		if (i > 1 && i < totalPages) {
			paginationItems.push(renderPage(i));
		}
	}

	if (active < totalPages - 2) {
		paginationItems.push(renderEllipsis("ell2")); // Ellipsis después del rango actual
	}

	if (totalPages > 1) {
		paginationItems.push(renderPage(totalPages)); // Última página
	}

	return (
		<div
			style={{
				display: "flex",
				flexWrap: "wrap",
				alignItems: "center",
				justifyContent: "start",
				gap: "8px",
				marginTop: "16px",
				fontSize: "14px",
				padding: "8px",
			}}
		>
			{paginationItems}
			<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
				<input
					type="number"
					value={customPage}
					onChange={(e) => setCustomPage(e.target.value)}
					onKeyDown={handleCustomPageSubmit}
					placeholder="#"
					style={{
						width: "80px",
						padding: "4px",
						border: "1px solid #d1d5db",
						borderRadius: "4px",
						outline: "none",
						transition: "border-color 0.2s",
					}}
					onFocus={(e) => (e.currentTarget.style.borderColor = "#3b82f6")}
					onBlur={(e) => (e.currentTarget.style.borderColor = "#d1d5db")}
				/>
				<button
					onClick={handleCustomPageSubmit}
					style={{
						padding: "4px 8px",
						backgroundColor: "#3b82f6",
						color: "#ffffff",
						border: "none",
						borderRadius: "4px",
						cursor: "pointer",
						transition: "background-color 0.2s",
					}}
					onMouseOver={(e) =>
						(e.currentTarget.style.backgroundColor = "#2563eb")
					}
					onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#3b82f6")}
				>
					Ir
				</button>
			</div>
		</div>
	);
};
