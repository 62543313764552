import axios from 'axios'

export const SHOW_CLIENT_PROFILE = 'SHOW_CLIENT_PROFILE'
export const UPDATE_CLIENT_PROFILE = 'UPDATE_CLIENT_PROFILE'
export const SHOW_CLIENT_ADDRESS = 'SHOW_CLIENT_ADDRESS'
export const UPDATE_CLIENT_ADDRESS = 'UPDATE_CLIENT_ADDRESS'


export const showClientProfile = () => ({
  type: SHOW_CLIENT_PROFILE,
  payload: axios.get('/account/profile')
    .then(response => response.data)
    .catch(error => Promise.reject(error))
  })

export const updateClientAccount = (user) => ({
  type: UPDATE_CLIENT_PROFILE,
  payload: axios.post('/account/account', user, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }})
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const updateClientAddress = (address, sameBillingAddress) => ({
  type: UPDATE_CLIENT_ADDRESS,
  payload: axios.put('/account/address', { ...address, sameBillingAddress })
  .then(response => response.data)
  .catch(error => Promise.reject(error))
})

