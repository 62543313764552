import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateClientAddress } from '../../actions/client'
import { Container, Row, Col, Form, FormGroup, Button } from 'react-bootstrap'
import UserSideMenu from '../../components/user-side-menu'
import CountrySelect from '../../components/country-select'
import { useSelector } from 'react-redux'
import ButtonLoader from '../../components/button-loader'

const ADDRESS_INITIAL_STATE = {
  name: '',
  last_name: '',
  email: '',
  phone: '',
  dni: '',
  street_ln_1: '',
  street_ln_2: '',
  city: '',
  province: '',
  country: '',
  country_code: '',
  zip_code: '',
}

const Address = () => {
  const {profile} = useSelector(state => state.showClientProfile)
  const [address, setAddress] = useState({
    bill_address: ADDRESS_INITIAL_STATE, 
    ship_address: ADDRESS_INITIAL_STATE
  })
  const [sameBillingAddress, setSameBillingAddress] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => { 
    setAddress({ bill_address: profile?.bill_address?? ADDRESS_INITIAL_STATE , ship_address: profile?.ship_address?? ADDRESS_INITIAL_STATE })
    if(!profile?.bill_address){
      setSameBillingAddress(true)
    }  
  }, [profile])

  const handleInputChange = ( typeAddress, e ) => {
    const { name, value } = e.target
    let newAddress = address[typeAddress] 
    newAddress[name] = value
    setAddress({...address, [typeAddress]: newAddress})
  }

  const selectCountry = (typeAddress, option) => {
    let newAddress = address[typeAddress]
    newAddress.country = option.value
    newAddress.country_code = option.code
    setAddress({...address, [typeAddress]: newAddress})
  }
  
  const onSubmit = (evt) => {
    evt.preventDefault()
    setIsSaving(true)
    dispatch(updateClientAddress(address, sameBillingAddress))
      .then(() => {
        alert("Dirección guardada correctamente!")
        setIsSaving(false)
      })
      .catch(()=> {
        alert("Se produjo un error al intentar guardar la dirección. Si el problema persiste, comuniquese con nosotros.");
        setIsSaving(false)
      })
  }

  return (
    <Container fluid className="inner-page desktop profile max-container">
      <div className="breadcrumb">
          Inicio / MI CUENTA
      </div>
      <h3>Mi Perfil</h3>
      <Row className="ml-0 mr-0">
        <UserSideMenu />
        <Col lg={7} className="profile-actions">
          <div className="green-box">
            <h4>DIRECCIÓN DE ENVÍO</h4>
            <Form onSubmit={onSubmit}>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label htmlFor="name">Nombre</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="name" 
                      value={address.ship_address.name}  
                      onChange={e => handleInputChange( 'ship_address', e )} 
                      required/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label htmlFor="last_name">Apellido</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="last_name" 
                      value={address.ship_address.last_name}  
                      onChange={e => handleInputChange( 'ship_address', e )} 
                      required/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label htmlFor="email">Email</Form.Label>
                    <Form.Control 
                      type="email" 
                      name="email" 
                      value={address.ship_address.email}  
                      onChange={e => handleInputChange( 'ship_address', e )}
                      required />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label htmlFor="phone">Teléfono</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="phone" 
                      value={address.ship_address.phone}  
                      onChange={e => handleInputChange( 'ship_address', e )} 
                      required/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label htmlFor="dni">Documento de identidad</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="dni" 
                      value={address.ship_address.dni}  
                      onChange={e => handleInputChange( 'ship_address', e )}
                      required />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label>Linea de calle 1</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="street_ln_1" 
                      value={address.ship_address.street_ln_1}
                      onChange={e => handleInputChange('ship_address', e)} 
                      required />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label>Línea de calle 2</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="street_ln_2" 
                      value={address.ship_address.street_ln_2} 
                      onChange={e => handleInputChange('ship_address', e)} 
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label htmlFor="city">Localidad / Ciudad</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="city" 
                      value={address.ship_address.city}  
                      onChange={e => handleInputChange('ship_address', e)} 
                      required/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label htmlFor="province">Provincia / Estado</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="province" 
                      value={address.ship_address.province}  
                      onChange={e => handleInputChange( 'ship_address', e)} 
                      required/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label htmlFor="country">Pais</Form.Label>
                    <CountrySelect
                      className="form-control country-select" 
                      name="country"
                      value={address.ship_address.country}
                      onCountrySelect={option => selectCountry( 'ship_address', option)}
                      placeholder="País"
                      isRequired={true}/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <FormGroup>
                    <Form.Label htmlFor="zip_code">Cód. Postal</Form.Label>
                    <Form.Control 
                      type="text" 
                      name="zip_code" 
                      value={address.ship_address.zip_code}  
                      onChange={e => handleInputChange( 'ship_address', e )} />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='mt-4 mb-5'>
                <Col xs={12}>
                  <Form.Check
                    label="Usar misma dirección para la facturación"
                    checked={sameBillingAddress}
                    onChange={() => setSameBillingAddress(!sameBillingAddress)}
                  />
                </Col>
              </Row>
              { !sameBillingAddress && 
                <>
                  <h4 className='mt-5'>DIRECCIÓN DE FACTURACIÓN</h4>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label htmlFor="name">Nombre</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="name" 
                          value={address.bill_address.name}  
                          onChange={e => handleInputChange( 'bill_address', e )}
                          required={sameBillingAddress? '' : 'required'} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label htmlFor="last_name">Apellido</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="last_name" 
                          value={address.bill_address.last_name}  
                          onChange={e => handleInputChange( 'bill_address', e )} 
                          required={sameBillingAddress? '' : 'required'}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label htmlFor="email">Email</Form.Label>
                        <Form.Control 
                          type="email" 
                          name="email" 
                          value={address.bill_address.email}  
                          onChange={e => handleInputChange( 'bill_address', e )}
                          required={sameBillingAddress? '' : 'required'} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label htmlFor="phone">Teléfono</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="phone" 
                          value={address.bill_address.phone}  
                          onChange={e => handleInputChange( 'bill_address', e )}
                          required={sameBillingAddress? '' : 'required'} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label htmlFor="dni">Documento de Identidad</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="dni" 
                          value={address.bill_address.dni}  
                          onChange={e => handleInputChange( 'bill_address', e )} 
                          required={sameBillingAddress? '' : 'required'}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label>Línea de calle 1</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="street_ln_1" 
                          value={address.bill_address.street_ln_1}
                          onChange={e => handleInputChange('bill_address', e)}
                          required={sameBillingAddress? '' : 'required'}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label>Línea de calle 2</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="street_ln_2" 
                          value={address.bill_address.street_ln_2} 
                          onChange={e => handleInputChange('bill_address', e)}
                          />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label htmlFor="city">Localidad / Ciudad</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="city" 
                          value={address.bill_address.city}  
                          onChange={e => handleInputChange('bill_address', e)}
                          required={sameBillingAddress? '' : 'required'}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label htmlFor="province">Provincia / Estado</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="province" 
                          value={address.bill_address.province}  
                          onChange={e => handleInputChange( 'bill_address', e)}
                          required={sameBillingAddress? '' : 'required'}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label htmlFor="country">Pais</Form.Label>
                        <CountrySelect
                          className="form-control country-select" 
                          name="country"
                          value={address.bill_address.country}
                          onCountrySelect={(option) => selectCountry( 'bill_address', option)}
                          placeholder="País"
                          isRequired={sameBillingAddress? false : true}
                          />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <FormGroup>
                        <Form.Label htmlFor="zip_code">Cód. Postal</Form.Label>
                        <Form.Control 
                          type="text" 
                          name="zip_code" 
                          value={address.bill_address.zip_code}  
                          onChange={e => handleInputChange( 'bill_address', e )} />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              }
              <ButtonLoader 
                isLoading={isSaving}
                loadingMessage="Guardando..."
                type="submit" 
                className="mt-5">
                  Guardar Cambios
              </ButtonLoader>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Address