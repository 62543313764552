import React from 'react'
import { Dropdown } from 'react-bootstrap'

const RecursiveDropdown = ({ menu, onItemClick }) => {
    const renderMenu = items => 
      items.map((item, index) =>
        <Dropdown key={index} style={{ marginLeft: 10 }}>
          <button onClick={() => onItemClick(item.slug)} >{item.name}</button>
          { item.all_children.length > 0 && 
              <>
                <Dropdown.Toggle split/>
                <Dropdown.Menu>
                  {renderMenu(item.all_children)}
                </Dropdown.Menu>
              </>      
          }
        </Dropdown>
      )
    
    return <div className="category-dropdown">{renderMenu(menu)}</div>
  }

export default RecursiveDropdown