import axios from 'axios'

export const CREATE_ORDER = 'CREATE_ORDER'
export const LIST_ORDERS = 'LIST_ORDERS'
export const SHOW_ORDER = 'SHOW_ORDER'

export const createOrder = (clientAddress, shipment, selectedOfficeId, payment, clientNotes) => ({
  type: CREATE_ORDER,
  payload: axios.post('/account/orders',{clientAddress, shipment, selectedOfficeId, payment, clientNotes},{
    params: {
      uuid: localStorage.getItem('entelequia-cart-uuid'),
      currency: localStorage.getItem('entelequia-currency')
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const listOrders = (page = 1) => ({
  type: LIST_ORDERS,
  payload: axios.get('/account/orders', {
    params: {
      page
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const showOrder = orderId => ({
  type: SHOW_ORDER,
  payload: axios.get(`/account/orders/${orderId}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})