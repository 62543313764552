import axios from 'axios'

export const GET_SHOP_NOTIFICATIONS = 'GET_SHOP_NOTIFICATIONS'
export const DELETE_SHOP_NOTIFICATIONS = 'DELETE_SHOP_NOTIFICATIONS'

export const getShopNotifications = () => ({
  type: GET_SHOP_NOTIFICATIONS,
  payload: axios.get('/account/shop-notifications')
    .then(response => response.data)
    .catch(error => Promise.reject(error))
  })

export const deleteShopNotifications = notificationId => ({
  type: DELETE_SHOP_NOTIFICATIONS,
  payload: axios.delete(`/account/shop-notifications/${notificationId}`)
    .then(response => ( response ))
    .catch(error => Promise.reject(error))
})