import React, { useEffect } from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

const RegistrationMessage = () => { 
  const location = useLocation()
  const history = useHistory()
  
  useEffect(() => {
    if(!location.state){
      history.push('/login')
    }
  },[])

    
  return (
    <Container fluid className="inner-page desktop profile max-container">
    <Row className="ml-0 mr-0">
      <Col lg={12} className="profile-actions">
        <div className="green-box">
          <h3 className="mt-5 mb-5 text-center">GRACIAS POR REGISTRARTE!</h3>
          <p className="text-center mb-5" style={{fontSize: '22px'}}>Ya podes disfrutar de todos los beneificios de <strong>Entelequia.</strong><br/>Para ingresar a tu cuenta hace click en el siguiente link.</p>
          <Link to="/login">
            INGRESAR
          </Link>
        </div>
      </Col>
    </Row>
  </Container>
  )
}

export default RegistrationMessage