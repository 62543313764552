import React from 'react'
import { NavLink } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import { logout } from '../helpers/logout'

const UserNavMenu = () => {
  
  return (
    <ListGroup>
      <ListGroup.Item><NavLink to="/cuenta" exact={true} activeClassName="active">Escritorio</NavLink></ListGroup.Item>
      <ListGroup.Item><NavLink to="/cuenta/mis-pedidos" activeClassName="active">Pedidos</NavLink></ListGroup.Item>
      <ListGroup.Item><NavLink to="/cuenta/favoritos" activeClassName="active">Favoritos</NavLink></ListGroup.Item>
      <ListGroup.Item><NavLink to="/cuenta/direccion" activeClassName="active">Dirección</NavLink></ListGroup.Item>
      <ListGroup.Item><NavLink to="/cuenta/perfil" activeClassName="active">Detalles de la cuenta</NavLink></ListGroup.Item>
      <ListGroup.Item><button onClick={()=> logout()}>Salir</button></ListGroup.Item>  
    </ListGroup>
  )
}

export default UserNavMenu