import {GET_TAG} from '../actions/tag'

const INITIAL_STATE = {
  tag: null,
  isFetching: false,
  isSuccess: false,
  error: null,
}

export const getTag = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case `${GET_TAG}_REJECTED`:
        return {
        ...state,
        error: action.payload,
        isFetching: false,
        isSuccess: false,
        }
    case `${GET_TAG}_PENDING`:
        return {
        ...state,
        isFetching: true,
        error: null,
        isSuccess: false,
        }
    case `${GET_TAG}_FULFILLED`:
        return {
        ...state,
        isFetching: false,
        tag: action.payload.tag,
        isSuccess: true,
        }
    case `${GET_TAG}`:
        return {
        ...state,
        isFetching: action.payload.state,
        isSuccess: false,
        }
    default: 
        return state
    }
}

