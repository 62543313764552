import{
  LIST_FAVORITES,
} from '../actions/favorite'
import { USER_LOGOUT } from '../actions/auth'

const INITIAL_STATE = {
  favorites: [],
  currentPage : null,
  numberOfPages: null,
  isFetching: false,
  isSuccess: false,
  error: null,
}
  
export const listFavorites = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case `${LIST_FAVORITES}_REJECTED`:
      return {
        ...state,
        fetched: false,
        validate: false,
        error: action.payload,
        isSuccess: false,
      }
    case `${LIST_FAVORITES}_PENDING`:
      return {
        ...state,
        isFetching: true,
        error: null,
        isSuccess: false,
      }
    case `${LIST_FAVORITES}_FULFILLED`:
      return {
        ...state,
        isFetching: false,
        favorites: action.payload.data,
        currentPage: action.payload.pagination.current_page,
        numberOfPages: action.payload.pagination.total_pages,
        isSuccess: true,
      }
    case `${LIST_FAVORITES}`:
      return {
        ...state,
        isFetching: action.payload.state,
        isSuccess: false,
      }
    case `${USER_LOGOUT}`:
        return {...INITIAL_STATE};
    default: 
      return state
  }
}