import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import ProductCardOffer from './product-card-offer'

const CarouselOffer = ({products}) => {
  const responsive = {
    0: {
      items: 1,
    },
    575: {
      items: 2,
    },
    1070: {
      items: 3,
    }
  }

  return (
    <OwlCarousel className='owl-theme' margin={10} dots={true} nav={true} responsive={responsive}>
      {products?.map((e,i) => 
        <ProductCardOffer key={i} product={e} className="item"/>
      )}
    </OwlCarousel>
  )

}

export default CarouselOffer