import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import InputSpinner from '../components/input-spinner'
import useAddToCart from '../hook/add-to-cart'
import { getGiftCards } from '../actions/giftcards'
import { PUBLIC_URL } from '../helpers/domain'


const GiftCard = () => {
  const [giftCards,setGiftCards] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [selectedGift, setSelectedGift] = useState(null)
  const [cartQuantity, setCartQuantity] = useState(1)
  const dispatch = useDispatch()
  const [isAddingToCart, addToCart ] = useAddToCart()
  let publicUrl = PUBLIC_URL

  useEffect(() => {
    setIsFetching(true)
    dispatch(getGiftCards())
      .then(response => {
        setGiftCards(response.value)
        setIsFetching(false)
      })
      .catch(()=>{
        setIsFetching(false)
      })
  },[])

  const addGiftCardToCart = () => {
    if(!selectedGift){
      alert('Primero debe seleccionar una giftcard!')
    } else{
      addToCart(selectedGift,cartQuantity)
    }
  }

  return (
    <Container fluid className="product inner-page max-container">
      {
        isFetching?
          <h3 className="mt-5 mb-5 pt-5 pb-5 border-0 text-center">Cargando...</h3>
        :
          <Row>
            <Col xs={12} md={5} className="product-photos mt-lg-5 pt-lg-4">
              <img  src={`${publicUrl}/images/giftcard_image.png`} className="img-fluid mt-5" alt="1" />
            </Col>
            <Col xs={12} md={{ span:6, offset:1}} className="product-info mt-5 mt-sm-0">
              <div className="breadcrumb d-none d-sm-block">
                <Link to="/">Inicio</Link> / <span>GIFTCARDS</span>
              </div>
              <h4 className="gift-title">GIFT CARD ENTELEQUIA</h4>
              {
                giftCards.length === 0 ?
                  <h5 className="no-item mt-5 pt-5 text-center">Por el momento, no hay gift cards disponibles</h5>
                :
                <>
                  <div className="gift-list">
                    <h5>Monto</h5>
                    { 
                      giftCards.map((e,i) => 
                        <div className={`gift-item ${selectedGift == e.id? 'selected' : ''}`} key={i} onClick={() => setSelectedGift(e.id)}>{e.price.currency} {e.price.amount}</div>
                      )
                    }
                  </div>
                  <div className="cart-control">
                    <InputSpinner quantity={cartQuantity} onClickHandler={quantity => setCartQuantity(quantity)}/>
                    <Button className="add-to-cart" onClick={() => addGiftCardToCart()}>Añadir al carrito</Button>
                  </div>
                  <div className="share">
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noreferrer"><img src={`${publicUrl}/images/icons/face-grey.svg`} alt="Facebook"/></a>
                    <a href={`https://api.whatsapp.com/send?text=${window.location.href}`} target="_blank" rel="noreferrer" ><img src={`${publicUrl}/images/icons/wap-grey.svg`} alt="Whats'app"/></a>
                    <span>Compartir</span>
                  </div>
                </>
              }
            </Col>
            <Col xs={12} className="description section">
              <h4 className="title-section">Como funciona...</h4>
              <p>¡Hacer un regalo nunca fue tan fácil!</p>
              <p>Nuestras Gift Cards llegaron para que resuelvas de forma sencilla (y genial!) la elección de un regalo. <br/>El valor de la Gift Card puede ser utilizado para la compra de cualquier producto en nuestra web o en nuestros locales.</p>
              <p>¿Lo mejor? ¡No tiene vencimiento!</p>
            </Col>
          </Row>
      }
    </Container>
  )
}

export default GiftCard