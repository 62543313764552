import { useState } from 'react'
import {useDispatch} from 'react-redux'
import { restoreCartAction, showCart } from '../actions/cart'
import { usePopup } from '../context/popup-context'
import ReactPixel from 'react-facebook-pixel'

const useRestoreCart = () => {
  const [isRestoringCart, setIsRestoringCart] = useState(false)
  const dispatch = useDispatch()
  const { setPopup } = usePopup()
    
  const restoreCart = (orderId) => {
    setIsRestoringCart(true)
    dispatch(restoreCartAction(orderId))
      .then((response) => {
        let cart = response.value;
        if(!localStorage.getItem('entelequia-cart-uuid') || localStorage.getItem('entelequia-cart-uuid') !== cart.uuid ){
          localStorage.setItem('entelequia-cart-uuid',cart.uuid)
        }
        setPopup('Se restauró el carrito correctamente!')
        dispatch(showCart())
        setIsRestoringCart(false)
        // ReactPixel.init('261944151355410');
        // ReactPixel.track('AddToCart',{ content_ids: [productId], content_type: 'product' }); 
      }).catch(error => {
        let msg = 'Error al restaurar el carrito. Si el problema persiste, comuniquese con nostros.'
        if(error.response.status == 422){
            msg = error.response.data.errorMessage
        }
        setIsRestoringCart(false)
        setPopup(msg) 
      })
  }

  return [isRestoringCart, restoreCart]
}

export default useRestoreCart